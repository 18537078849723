.classroom {
    .edustion-title {
        color: #f8b73a;
        p {
            position: relative;
            font-weight: bold;
            margin-bottom: 0;
        }
        p:before {
            position: absolute;
            content: '';
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/classroom1.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
        p:after {
            position: absolute;
            content: '';
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/classroom2.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
    }
    .edustion-container {
        .bk-img-edustion {
            background: url(../images/classroom4.png) left bottom;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            padding-bottom: px2rem(64px);
            @media screen and (max-width: 1240px) {
                background: none;
            }
        }
        .edustion-produceAdvantages {
            background-color: #fdb52c;
            background-image: url(../images/classroom6.png);
            background-repeat: no-repeat;
            background-position: 100% 90%;
            @media screen and (max-width: 1240px) {
                background-image: none;
            }
            .produceAdvantages-content {
                .content-box {
                    .box:hover {
                        .right-info {
                            .b {
                                color: #fff;
                                transform: translateX(5px);
                            }
                        }
                    }
                }
            }
        }
        .edustion-produceValue {
            .produceValue-content {
                .content-box {
                    .box {
                        border: 2px solid #f8b73a;
                    }
                }
            }
        }
        .edustion-appCase {

            .appCase-content {

                .swiper-slide {

                    .right {
                        background-color: #fdb52c;

                    }
                }
                .same:hover {
                    background-color: #fdb52c;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}