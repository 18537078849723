.edu-container {
    .tree-title {
        .title-right {
            .left-box {
                .cn {
                    @media screen and (max-width: 500px) {
                        padding-bottom: px2rem(12px);
                    }
                }
                .en {
                    @media screen and (max-width: 500px) {
                        padding-top: px2rem(12px);
                    }
                }
            }
        }
        .title-btn {
            background-color: $baseColor;
        }
    }
    .edu-content {
        padding-top: 60px;
        padding-bottom: 70px;
        background-color: #f5f5f5;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .edu-info {
            display: flex;
            margin-top: 40px;
            border-radius: 25px;
            overflow: hidden;
            background-color: #fff;
            @media screen and (max-width: 1240px) {
                margin-top: 30px;
            }
            @media screen and (max-width: 800px) {
                display: block;
            }
            .left {
                width: (660 / 1200) * 100%;
                padding: 64px 60px 20px 55px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    padding: px2rem(40px);
                }
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
                .name {
                    font-size: 24px;
                    line-height: 54px;
                    font-weight: bold;
                    color: #888;
                    @media screen and (max-width: 1240px) {
                        font-size: 18px;
                        line-height: 1.3;
                        text-align: center;
                    }
                }
                .dsc {
                    margin-top: 15px;
                    font-size: 16px;
                    line-height: 35px;
                    color: #888;
                    @include articleReset(16px, 35px);
                    @media screen and (max-width: 1240px) {
                        font-size: 14px;
                        line-height: 20px;
                        @include articleReset(14px, 20px);
                    }
                }
                .left-num {
                    margin-top: 100px;
                    display: flex;
                    padding-left: px2rem(30px);
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(60px);
                    }
                    @media screen and (max-width: 800px) {
                        justify-content: space-between;
                        padding-left: 0;
                    }
                    .box {
                        margin-right: 100px;
                        @media screen and (max-width: 800px) {
                            margin-right: 10px;
                        }
                        .top {
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            span {
                                display: block;
                            }
                            .num {
                                font-size: 50px;
                                line-height: 1;
                                font-family: Impact;
                                color: #1ca7a2;
                                @media screen and (max-width: 1240px) {
                                    font-size: 36px;
                                }
                                @media screen and (max-width: 800px) {
                                    font-size: 24px;
                                }
                            }
                            .yr {
                                font-size: 20px;
                                line-height: 1;
                                color: #1ca7a2;
                                font-weight: bold;
                                @media screen and (max-width: 1240px) {
                                    font-size: 16px;
                                }
                                @media screen and (max-width: 800px) {
                                    font-size: 14px;
                                }
                            }
                        }
                        .bottom {
                            font-size: 16px;
                            line-height: 1.3;
                            color: #888;
                            text-align: center;
                            margin-top: 5px;
                        }
                    }
                    .box:nth-last-of-type(1) {
                        margin-right: 0;
                        .top {
                            align-items: flex-end;
                        }
                    }
                }
            }
            .right {
                width: (540 / 1200) * 100%;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
                .img {
                    width: 100%;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
    .jt-content {
        background-color: #fff;
        padding: 94px 0 166px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .jt-title {
            width: 100%;
            .b {
                font-size: 42px;
                line-height: 54px;
                font-weight: bold;
                color: #1ca7a2;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
            .s {
                text-align: center;
                font-size: 16px;
                line-height: 35px;
                color: #888;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
        }
        .jt-img {
            width: 100%;
            margin-top: 10px;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .lc-content {
        background-repeat: no-repeat !important;
        padding: 82px 0 67px;
        width: 100%;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        @media screen and (max-width: 800px) {
            background: none !important;
            background-color: #f5f5f5 !important;
        }
        .lc-dsc {
            margin-top: 87px;
            width: 100%;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            .b {
                font-size: 43px;
                line-height: 53px;
                font-weight: bold;
                color: #002625;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
            .s {
                font-size: 16px;
                line-height: 35px;
                color: #002625;
                width: 55%;
                margin: 25px auto 0;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                    width: 80%;
                }
            }
        }
        .lc-info {
            margin-top: 68px;
            //overflow: hidden;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            .mb-selector {
                display: none;
                margin: 0 -5px px2rem(30px);
                @media screen and (max-width: 800px) {
                    display: flex;
                }
                .box {
                    flex: 1;
                    text-align: center;
                    padding: 5px 0px;
                    background-color: $baseColor;
                    color: #fff;
                    box-sizing: border-box;
                    margin: 0 5px;
                    font-size: 16px;
                }
                .on {
                    opacity: .8;
                }
            }
            .content {
                display: flex;
                flex-wrap: wrap;
                .content-box {
                    flex: 1;
                    box-sizing: border-box;
                    @media screen and (max-width: 800px) {
                        flex: none;
                        width: 100%;
                    }
                    .box {
                        cursor: pointer;
                        position: relative;
                        background-color: #fff;
                        padding: 65px 53px 46px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            padding: px2rem(40px) px2rem(30px) px2rem(30px);
                        }
                        .class {
                            position: relative;
                            font-size: 25px;
                            line-height: 1;
                            color: #4c4c4c;
                            text-align: center;
                            padding-bottom: px2rem(10px);
                            box-sizing: border-box;
                            @media screen and (max-width: 800px) {
                                font-size: 18px;
                            }
                        }
                        .class:before {
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: px2rem(66px);
                            height: px2rem(4px);
                            border-radius: px2rem(4px);
                            background-color: #1ca7a2;
                        }
                        .dsc {
                            margin-top: px2rem(10px);
                            font-size: 16px;
                            line-height: 19px;
                            color: #4c4c4c;
                            text-align: center;
                            @media screen and (max-width: 800px) {
                                font-size: 14px;
                                line-height: 1.3;
                            }
                        }
                        .img {
                            margin-top: px2rem(34px);
                            text-align: center;
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                        .box-hover {
                            display: none;
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            right: -262px;
                            width: 262px;
                            height: 100%;
                            border: 2px solid $baseColor;
                            box-sizing: border-box;
                            background-color: #fff;
                            padding: 38px 20px 10px 46px;
                            @media screen and (max-width: 1240px) {
                                padding: px2rem(20px);
                            }
                            @media screen and (max-width: 800px) {
                                position: static;
                                width: 100%;
                                display: block;
                                padding: px2rem(30px);
                                border: none;
                            }
                            .list-li {
                                margin-bottom: 20px;
                                width: 100%;
                                .name {
                                    position: relative;
                                    font-size: 16px;
                                    line-height: 19px;
                                    font-weight: bold;
                                    color: #002c2a;
                                    @media screen and (max-width: 1240px) {
                                        font-size: 15px;
                                        line-height: 1.2;
                                    }
                                }
                                .name:before {
                                    position: absolute;
                                    content: '';
                                    top: 4px;
                                    left: -14px;
                                    width: 7px;
                                    height: 7px;
                                    background-color: $baseColor;
                                    border-radius: 50%;
                                }
                                .dsc {
                                    font-size: 16px;
                                    line-height: 19px;
                                    color: #002c2a;
                                    text-align: left;
                                    margin-top: 4px;
                                    @media screen and (max-width: 1240px) {
                                        font-size: 15px;
                                        line-height: 1.2;
                                    }
                                }
                            }
                            .list-li:nth-last-of-type(1) {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .box:hover {
                        background-color: $baseColor;
                        @media screen and (max-width: 800px) {
                            background-color: #fff;
                        }
                        .class {
                            color: #fff;
                            @media screen and (max-width: 800px) {
                                color: #4c4c4c;
                            }
                        }
                        .class:before {
                            background-color: #fff;
                            @media screen and (max-width: 800px) {
                                background-color: #1ca7a2;
                            }
                        }
                        .dsc {
                            color: #fff;
                            @media screen and (max-width: 800px) {
                                color: #4c4c4c;
                            }
                        }
                        .box-hover {
                            display: block;
                        }
                    }
                }


                .swiper-slide:nth-last-of-type(1) {
                    .box {
                        .box-hover {
                            right: auto;
                            left: -262px;
                        }
                    }
                }
            }
        }
    }
    .ts-content {
        background-color: #fff;
        padding: 90px 0 230px;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .ts-title {
            margin-top: 95px;
            font-size: 43px;
            line-height: 53px;
            font-weight: bold;
            color: #1ca7a2;
            text-align: center;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .ts-img {
            margin-top: 65px;
            text-align: center;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .pb-content {
        background-repeat: no-repeat !important;
        padding-top: 20px;
        @media screen and (max-width: 1240px) {
            background: none !important;
            background-color: #f5f5f5 !important;
            padding: px2rem(60px) 0;
        }
        .pb-top {
            max-width: 440px;
            width: 100%;
            padding-top: 55px;
            @media screen and (max-width: 1240px) {
                padding-top: 0;
                max-width: none;
            }
            .pb-title {
                font-size: 43px;
                line-height: 53px;
                font-weight: bold;
                color: #1ca7a2;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
            .pb-dsc {
                font-size: 16px;
                line-height: 35px;
                color: #002625;
                margin-top: 30px;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
            .img {
                display: none;
                width: 100%;
                text-align: center;
                margin-top: 30px;
                @media screen and (max-width: 1240px) {
                    display: block;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .pb-bottom {
            margin-top: 750px;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            .pb-title {
                font-size: 43px;
                line-height: 53px;
                font-weight: bold;
                color: #fff;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 20px;
                    line-height: 1.3;
                    color: #002625;
                }
            }
            .pb-dsc {
                font-size: 16px;
                line-height: 35px;
                color: #fff;
                max-width: 650px;
                margin: 30px auto 0;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                    color: #002625;
                }
            }
            .pb-img {
                margin-top: 45px;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    margin-top: 30px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .py-content {
        padding-top: 105px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding-top: px2rem(60px);
        }
        .py-title {
            font-size: 43px;
            line-height: 53px;
            font-weight: bold;
            color: #1ca7a2;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .py-dsc {
            font-size: 16px;
            line-height: 35px;
            color: #002625;
            max-width: 590px;
            margin: 30px auto 0;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 14px;
                line-height: 1.3;
            }
        }
        .py-img {
            margin-top: 125px;
            text-align: center;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .xq-content {
        padding: 170px 0 156px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .py-title {
            font-size: 43px;
            line-height: 53px;
            font-weight: bold;
            color: #1ca7a2;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .py-dsc {
            font-size: 16px;
            line-height: 35px;
            color: #002625;
            max-width: 650px;
            margin: 30px auto 0;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 14px;
                line-height: 1.3;
            }
        }
        .py-img {
            margin-top: 72px;
            text-align: center;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .jy-content {
        background-color: #f5f5f5;
        padding: 60px 0 110px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .jy-dsc {
            font-size: 16px;
            line-height: 35px;
            color: #002625;
            max-width: 700px;
            text-align: center;
            margin: 62px auto 0;
            @media screen and (max-width: 1240px) {
                font-size: 14px;
                line-height: 1.3;
                margin: px2rem(40px) auto 0;
            }
        }
        .jy-info {
            display: flex;
            margin-top: 80px;
            width: 100%;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            @media screen and (max-width: 800px) {
                display: block;
            }
            .left {
                width: (640 / 1200) * 100%;
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .right {
                width: (560 / 1200) * 100%;
                padding-left: 30px;
                box-sizing: border-box;
                @media screen and (max-width: 800px) {
                    width: 100%;
                    padding-left: 0;
                    margin-top: px2rem(30px);
                }
                .tj-swiper {
                    width: 100%;
                    .swiper-slide {
                        position: relative;
                        width: 363px;
                        @media screen and (max-width: 1240px) {
                            width: 80%;
                        }
                        @media screen and (max-width: 1000px) {
                            width: 70%;
                        }
                        //@media screen and (max-width: 750px) {
                        //    width: 65%;
                        //}
                        .img {
                            position: relative;
                            width: 100%;
                            padding-bottom: (537 / 363) * 100%;
                            img {
                                @include baseImg;
                            }
                        }
                    }
                    .swiper-slide:before {
                        position: absolute;
                        z-index: 2;
                        content: '';
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, .2);
                    }
                    .swiper-slide-active:before {
                        content: none;
                    }
                }
            }
        }
    }
    .fw-content {
        background-color: #fff;
        padding: 80px 0 75px;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .fw-title {
            font-size: 43px;
            line-height: 53px;
            font-weight: bold;
            color: #1ca7a2;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .fw-dsc {
            font-size: 16px;
            line-height: 35px;
            color: #888;
            max-width: 650px;
            margin: 0px auto;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 14px;
                line-height: 1.3;
            }
        }
        .fw-info {
            width: 100%;
            margin-top: px2rem(31px);
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    .jb-content {
        background-color: #f5f5f5;
        padding: 65px 0 96px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .jb-info {
            position: relative;
            margin-top: 76px;
            width: 100%;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            .box {
                width: 100%;
                position: relative;
                padding-bottom: (373 / 343) * 100%;
                overflow: hidden;
                img {
                    @include baseImg;
                    transition: all .3s;
                }
            }
            .same {
                position: absolute;
                z-index: 4;
                top: 50%;
                transform: translateY(-50%);
                width: 54px;
                height: 54px;
                border-radius: 50%;
                background-color: $baseColor;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: .2;
                cursor: pointer;
                @media screen and (max-width: 1240px) {
                    width: 30px;
                    height: 30px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    @media screen and (max-width: 1240px) {
                        max-width: none;
                        width: 20px;
                    }
                }
            }
            .same:hover {
                opacity: 1;
            }
            .jb-prev {
                left: 360px;
                @media screen and (max-width: 1240px) {
                    left: -30px;
                }
                @media screen and (max-width: 750px) {
                    left: 0px;
                }
            }
            .jb-next {
                right: 360px;
                @media screen and (max-width: 1240px) {
                    right: -30px;
                }
                @media screen and (max-width: 750px) {
                    right: 0px;
                }
            }
        }
    }
    .dz-content {
        padding: 88px 0 62px;
        box-sizing: border-box;
        background-color: #1ca7a2;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .dz-title {
            font-size: 43px;
            line-height: 53px;
            font-weight: bold;
            color: #fff;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 20px;
                line-height: 1.3;
            }
        }
        .dz-info {
            display: flex;
            flex-wrap: wrap;
            margin: px2rem(46px) px2rem(-14px);
            .content-box {
                padding: px2rem(14px);
                box-sizing: border-box;
                width: 33.3%;
                @media screen and (max-width: 1240px) {
                    width: 50%;
                }
                .box {
                    width: 100%;
                    border: 1px solid #fff;
                    height: 100%;
                    .name {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 24px;
                        line-height: 51px;
                        height: 51px;
                        font-weight: bold;
                        color: $baseColor;
                        text-align: center;
                        background-color: #fff;
                        @media screen and (max-width: 1240px) {
                            font-size: 16px;
                            line-height: 22px;
                            height: 44px;
                        } 
                        span {
                            display: block;
                        }
                    }
                    .dz {
                        padding: 4px 10px;
                        box-sizing: border-box;
                        font-size: 16px;
                        line-height: 20px;
                        color: #fff;
                        border-bottom: 1px solid #fff;
                        @media screen and (max-width: 1240px) {
                            padding: 10px;
                            font-size: 15px;
                            line-height: 1.2;
                        }
                        @media screen and (max-width: 500px) {
                            line-height: 18px;
                            height: 74px;
                        }
                    }
                    .dh {
                        padding: 0 10px;
                        box-sizing: border-box;
                        font-size: 16px;
                        line-height: 20px;
                        color: #fff;
                        @media screen and (max-width: 1240px) {
                            padding: 10px;
                            font-size: 15px;
                            line-height: 1.2;
                        }
                    }
                }
            }
        }
    }
    .lq-content {
        width: 100%;
        padding: 80px px2rem(30px) 160px;
        background-color: #fff;

        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) px2rem(30px);
        }
        .lq-info {
            position: relative;
            display: block;
            background-repeat: no-repeat !important;
            width: 100%;
            height: 153px;
            border-radius: 153px;
            overflow: hidden;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                background: none !important;
                height: auto;
                background-color: $baseColor !important;
                padding: px2rem(30px);
                border-radius: 0px;
            }
            .lq-box {
                margin-top: 100px;
                @media screen and (max-width: 1240px) {
                    margin-top: 0;
                }
                .word {
                    display: none;
                    font-size: 30px;
                    line-height: 45px;
                    color: #fff;
                    @media screen and (max-width: 1240px) {
                        display: block;
                        font-size: 22px;
                        line-height: 1.3;
                        margin-top: 10px;
                    }
                    @media screen and (max-width: 800px) {
                        font-size: 18px;
                    }
                }
                input,
                textarea,
                select {
                    display: block;
                    border: none;
                    outline: none;
                    background: none;
                    color: #666;
                    width: 100%;
                    font-size: 23px;
                    list-style: none;
                    text-align: center;
                    line-height: 34px;
                    @media screen and (max-width: 800px) {
                        font-size: 14px;
                    }
                }
                select {
                    line-height: 34px;
                    height: 34px;
                    appearance: none;
                    -webkit-appearance: none;
                    -moz-appearance: none;

                }
                select::-ms-expand { display: none; }
                .input-group25 {
                    display: flex;
                    flex-wrap: wrap;
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(20px);
                    }
                    li {
                        margin-bottom: 15px;
                        padding: 0 px2rem(12px);
                        width: 25%;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            width: 50%;
                        }
                    }
                }
                .input-box,
                .select-box {
                    display: flex;
                    height: 34px;
                    line-height: 34px;
                    background: #fff;
                    padding: 0 10px;
                }
                .input {
                    width: 100%;
                }
                .select-box {
                    .select {
                        width: 100%;
                        select {
                            width: auto;
                            margin: auto;
                        }

                    }
                }
                button {
                    position: absolute;
                    top: 20px;
                    left: 25.5%;
                    font-size: 45px;
                    color: #fff;
                    line-height: 1;
                    border: none;
                    outline: none;
                    cursor: pointer;
                    background: none;
                    @media screen and (max-width: 1800px) {
                        left: 20%;
                    }
                    @media screen and (max-width: 1550px) {
                        left: 18%;
                    }
                    @media screen and (max-width: 1500px) {
                        left: 16%;
                    }
                    @media screen and (max-width: 1450px) {
                        left: 14%;
                    }
                    @media screen and (max-width: 1260px) {
                        left: 12%;
                    }
                    @media screen and (max-width: 1240px) {
                        position: static;
                        padding: 10px;
                        background-color: #fac14a;
                        font-size: 32px;
                        border-radius: 32px;
                    }
                    @media screen and (max-width: 800px) {
                        font-size: 26px;
                        border-radius: 26px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 20px;
                        border-radius: 20px;
                    }
                }
            }
        }
    }
}