
.header {
    //栏目头部
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;
    box-shadow: 0 10px 10px -5px rgba(0, 0, 0, .1);
    .header-web-tree {
        width: 100%;
        margin: 0 auto !important;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        z-index: 100;
        //padding: 0 160px;
        box-sizing: border-box;
        // display: none;

        .header-right {
            display: flex;
            height: 100%;
        }
        .header-nav {
            height: 100%;
            margin-left: 100px;
            .header-ul {
                display: flex;
                height: 100%;
                .header-li:nth-of-type(1) {
                    margin-left: 0px !important;
                }
                .header-li {
                    position: relative;
                    text-align: center;
                    margin-left: 40px;
                    height: 100%;
                    box-sizing: border-box;
                    @media screen and (max-width: 1260px) {
                        margin-left: 25px;
                    }
                    .nav1 {
                        position: relative;
                        display: inline-block;
                        font-size: 16px;
                        text-align: center;
                        white-space: nowrap;
                        line-height: 100px;
                    }
                    .nav1:before {
                        display: none;
                        position: absolute;
                        bottom: 13px;
                        left: 50%;
                        transform: translateX(-50%);
                        content: '';
                        min-width: 100%;
                        height: 5px;
                        border-radius: 5px;
                        box-sizing: border-box;
                        background-color: $baseColor;
                        transition: all .3s;
                    }
                    .hover-show {
                        position: absolute;
                        display: none;
                        top: 100px;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: #f5f5f5;
                        z-index: 999;
                        width: 120px;
                        ul {
                            li {
                                text-align: center;
                                padding: 0 10px;
                                box-sizing: border-box;
                                border-bottom: 1px solid #e8e8e8;
                                a {
                                    display: block;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    font-size: 14px;
                                    width: 100%;
                                    height: 100%;
                                    text-align: center;
                                    color: #333;
                                    line-height: 45px;
                                }
                            }
                            li:nth-last-of-type(1) {
                                border-bottom: 0px;
                            }
                            li:hover {
                                a {
                                    color: $baseColor;
                                }
                            }
                        }
                    }
                }
                .on {
                    .nav1 {
                        color: $baseColor;
                    }
                    .nav1:before {
                        display: block;
                    }
                }
                .header-li:hover {
                    .nav1 {
                        color: $baseColor;
                    }
                    .hover-show {
                        display: block;
                    }
                    .nav1:before {
                        display: none;
                    }
                }
            }
        }
        .header-search {
            margin-left: 30px;
            position: relative;
            display: flex;
            align-items: center;
            @media screen and (max-width: 1260px) {
                margin-left: 15px;
            }
            .header-btn {
                cursor: pointer;
                position: relative;
                .sousuo {
                    font-weight: bold;
                    display: inline-block;
                    width: 40px;
                    height: 40px;
                    background-color: #fff;
                    text-align: center;
                    line-height: 40px;
                    font-size: 20px;
                    color: #333;
                    border-radius: 50%;
                }
            }
            .header-search-form {
                animation-name: r2l;
                -webkit-animation-name: r2l;
                -ms-animation-name: r2l;
                animation-timing-function: ease;
                -webkit-animation-timing-function: ease;
                -ms-animation-timing-function: ease;
                animation-fill-mode: forwards;
                -webkit-animation-fill-mode: forwards;
                -ms-animation-fill-mode: forwards;
                animation-duration: .3s;
                -webkit-animation-duration: .3s;
                -ms-animation-duration: .3s;
            }
        }
        .header-search:hover {
            .header-btn {
                .sousuo {
                    background-color: $baseColor;
                    color: #fff;
                }
            }
            .header-search-form {
                display: block;
            }
        }
    }
    @keyframes r2l {
        0% {
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            transform: translateX(0);
            opacity: 1;
        }
    }
    @-webkit-keyframes r2l {
        0% {
            -webkit-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            opacity: 1;
        }
    }
    @-ms-keyframes r2l {
        0% {
            -ms-transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -ms-transform: translateX(0);
            opacity: 1;
        }
    }
    //搜索框
    .header-search-form {
        display: none;
        position: absolute;
        right: -16px;
        bottom: -20px;
        width: 240px;
        background-color: #f5f5f5;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 40px;
        transition: all .3s;
        z-index: 6;
        form {
            display: flex;
        }
        .input {
            flex: 1;
            min-width: 0;
        }
        input,
        button {
            display: block;
            height: 40px;
            line-height: 40px;
            outline: none;
            background: none;
            border: none;
        }
        input {
            padding-left: 20px;
            width: 100%;
            color: #555;
            box-sizing: border-box;
        }
        button {
            padding: 0 16px;
            font-size: 18px;
            color: #333;
            cursor: pointer;
            transition: all .3s;
            button:hover {
                color: #e6212a;
            }
        }
        ::-webkit-input-placeholder {
            color: #666;
        }
        ::-moz-placeholder {
            color: #666;
        }
        :-ms-input-placeholder {
            color: #666;
        }
    }
    //手机版头部
    .header-mobile {
        display: none;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100px;
        padding: px2rem(20px);
        box-sizing: border-box;
        z-index: 100;
        @media screen and (max-width: 500px) {
            height: 60px;
        }
        .header-mobile-logo {
            img {
                display: block;
                width: px2rem(236px);
                min-width: 100px;
                height: auto;
            }
        }
        .header-mobile-right {
            .header-search-form {
                min-width: 160px;
                .icon-sousuo {
                    font-size: 16px;
                }
            }
            .btn {
                cursor: pointer;
            }
            .icon-sousuo {
                color: #5e5e5e;
                font-size: px2rem(30px);
                @media screen and (max-width: 500px) {
                    font-size: 18px !important;
                }
            }
            .icon-daohangmoren {
                margin-left: px2rem(50px);
                color: #5e5e5e;
            }
            .icon-daohangmoren:before {
                font-size: px2rem(30px);
                content: '\e665';
                transition: all .3s;
                color: #5e5e5e;
                font-weight: bolder;
                @media screen and (max-width: 500px) {
                    font-size: 18px !important;
                }
            }
            .active:before {
                content: '\e6a0';
                font-size: px2rem(30px) !important;
                @media screen and (max-width: 500px) {
                    font-size: 18px !important;
                }
            }
        }
    }
    //web下拉导航
    .header-index-mobile {
        position: absolute;
        .header-mobile-right {
            .icon-sousuo {
                color: #fff;
            }
            .icon-daohangmoren {
                color: #fff;
            }
        }
    }
    .header-img img {
        display: block;
        width: 100%;
    }
    .header-mobile-img {
        display: none;
        img {
            display: block;
            width: 100%;
        }
    }
    @media screen and (max-width: 1000px) {
        .header-web {
            display: none;
        }
        .header-web-tree {
            display: none;
        }
        .header-img {
            display: none;
        }
        .header-mobile {
            display: flex;
        }
        .header-mobile-img {
            display: block;
        }
        .header-mobile-right {
            display: flex;
            justify-content: left;
            align-items: center;
            .header-search-position {
                width: px2rem(200px);
                min-width: 140px;
                top: px2rem(120px);
                right: px2rem(70px);
                form {
                    .icon-sousuo {
                        margin-right: 0px !important;
                    }
                }
            }
            .icon-daohangmoren {
                display: inline-block;
            }
        }
    }
}
.nav-mobile {
    position: absolute;
    width: px2rem(530px);
    top: 0px;
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #f5f5f5;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-search {
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgba(0, 0, 0, .2);
        height: px2rem(170px);
        display: flex;
        z-index: 104;
        box-sizing: border-box;
        align-items: center;
        padding: 0 px2rem(50px);
        width: 100%;
        transform: translateX(100%);
        transition: transform .4s ease;
        .box {
            //height: px2rem(90px);
            border: 1px solid #e2e2e2;
            border-radius: px2rem(45px);
            background-color: #fff;
            display: flex;
            padding: 0 px2rem(30px);
            width: 100%;
            box-sizing: border-box;
        }
        .input {
            flex: 1;
            overflow: hidden;
        }
        input[type="text"] {
            width: 100%;
            height: px2rem(90px);
            line-height: px2rem(90px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
        }
        button {
            width: px2rem(90px);
            height: px2rem(90px);
            border: none;
            appearance: none;
            //font-family: iconfont;
            display: block;
            background: none;
            font-size: px2rem(40px);
            color: #666;
            //&:before {
            //    content: '\e627';
            //}
        }
    }
    &-con {
        display: none;
        position: fixed;
        top: 100px;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 8889;
        transition: transform .4s ease;
        @media screen and (max-width: 500px) {
            top: 60px;
        }
        .bg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .4);
            z-index: 1;
        }
        &.anim {
            .nav-mobile-search {
                transform: translateX(0) !important;
            }
        }
    }
    &-wp {
        position: relative;
        & > li {
            border-bottom: 1px solid #e2e2e2;
            &:first-child {
                border-top: 1px solid #e2e2e2;
            }
        }
    }
    &-title {
        position: relative;
        > a {
            position: relative;
            display: block;
            padding: 0 px2rem(30px) 0 px2rem(65px);
            width: 100%;
            line-height: px2rem(100px);
            font-size: px2rem(32px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            box-sizing: border-box;
        }
        &.open {
            .nav-mobile-child-btn i {
                &:before {
                    content: '\e613';
                }
            }
        }
    }
    &-child-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 0 px2rem(30px) 0 px2rem(65px);
        text-align: right;
        line-height: px2rem(100px);
        box-sizing: border-box;
        z-index: 1;
        i {
            font-size: px2rem(22px);
            color: #333;
            font-family: "iconfont";
            transition: all .3s;
            &:before {
                content: "\e616";
            }
        }
        + a {
            display: inline-block;
            width: calc(100% - 50px);
        }
    }
    .sub {
        display: none;
        border-top: 1px solid #e2e2e2;
        li {
            position: relative;
            overflow: hidden;
            &:not(:last-child) a {
                border-bottom: 1px solid #e2e2e2;
            }
            a {
                display: block;
                padding: 0 px2rem(30px) 0 px2rem(65px);
                line-height: px2rem(100px);
                height: px2rem(100px);
                font-size: px2rem(28px);
                color: #666;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}

.header-tree-banner {
    overflow: hidden;
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
    .img-pc,
    .img-mobile {
        position: relative;
        padding-bottom: (420 / 1920) *100%;
        overflow: hidden;
        img {
            position: absolute;
            left: 0;
            top: 0;
            //transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
        }
        video {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            outline: none;
            cursor: pointer;
        }
    }
    .img-mobile {
        padding-bottom: (320 / 750) *100%;
    }
}
.banner-web {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}
.banner-mobile {
    display: none;
    @media screen and (max-width: 1000px) {
        display: block;
    }
}