.eRoom {
    .edustion-title {
        color: #eb6b3e;
        p {
            position: relative;
            font-weight: bold;
            margin-bottom: 0;
        }
        p:before {
            position: absolute;
            content: '';
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/eroom1.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
        p:after {
            position: absolute;
            content: '';
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/eroom2.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
    }
    .edustion-container {
        .edustion-produceAdvantages {
            background-color: #eb6b3e;
            background-image: url(../images/eroom4.png);
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                background-image: none;
            }
        }
        .edustion-produceValue {
            padding: px2rem(92px) px2rem(235px) 0;
            @media screen and (max-width: 1000px) {
                padding: px2rem(92px) px2rem(40px) 0;
            }
            .produceValue-content {
                .content-box {
                    width: 50%;
                    padding: px2rem(9px);
                    box-sizing: border-box;
                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                    .box {
                        border: 2px solid #eb6b3e;
                    }
                }
            }
        }
        .edustion-appCase {

            .appCase-content {

                .swiper-slide {

                    .right {
                        background-color: #eb6b3e;

                    }
                }
                .same:hover {
                    background-color: #eb6b3e;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}