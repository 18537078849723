.about-list {
    background-color: #f5f5f5;
    padding: px2rem(60px) 0 px2rem(100px);
    .wp1200 {
        overflow: hidden;
    }
    .list-content {
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(-15px);
        padding-bottom: px2rem(50px);
        .content-box {
            width: 33.3%;
            box-sizing: border-box;
            padding: px2rem(15px);
            overflow: hidden;
            @media screen and (max-width: 1000px) {
                width: 50%;
            }
            .box {
                position: relative;
                width: 100%;
                overflow: hidden;
                .img {
                    width: 100%;
                    position: relative;
                    padding-bottom: (277 / 370) * 100%;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    overflow: hidden;
                    img {
                        @include baseImg;
                    }
                }
                .dsc {
                    display: block;
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 22px 20px 24px 27px;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    overflow: hidden;
                    background-color: #fff;
                    @media screen and (max-width: 1600px) {
                        padding: 20px;
                    }
                    @media screen and (max-width: 1000px) {
                        padding: px2rem(26px) px2rem(26px) px2rem(30px);
                    }
                    .title {
                        font-size: 18px;
                        line-height: 30px;
                        color: #333;
                        height: 60px;
                        overflow: hidden;
                        @media screen and (max-width: 1600px) {
                            font-size: 16px;
                            line-height: 24px;
                            height: 48px;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: px2rem(26px);
                            line-height: px2rem(35px);
                            height: px2rem(70px);
                        }
                        @media screen and (max-width: 500px) {
                            font-size: 13px;
                            line-height: 18px;
                            height: 36px;
                        }
                    }
                    .bottom-box {
                        margin-top: px2rem(10px);
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        @media screen and (max-width: 1000px) {
                            display: block;
                        }
                        .time {
                            font-size: 14px;
                            line-height: 30px;
                            font-family: Arial;
                            color: #999;
                            white-space: nowrap;
                            @media screen and (max-width: 1600px) {
                                font-size: 12px;
                                line-height: 24px;
                            }
                            @media screen and (max-width: 1000px) {
                                font-size: px2rem(22px);
                                line-height: px2rem(30px);
                            }
                        }
                        .lx {
                            display: flex;
                            @media screen and (max-width: 1000px) {
                                margin-top: 10px;
                            }
                            .same {
                                display: block;
                                width: 50px;
                                height: 25px;
                                line-height: 25px;
                                border-radius: 5px;
                                text-align: center;
                                background-color: #eee;
                                font-size: 14px;
                                margin-right: 5px;
                                color: #999;
                                @media screen and (max-width: 500px) {
                                    width: 40px;
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 12px;
                                    margin-right: 4px;
                                }
                            }
                            .same:nth-last-of-type(1) {
                                margin-right: 0px;
                            }
                        }
                    }

                    .btn {
                        display: none;
                        margin-top: 20px;
                        margin-bottom: 19px;
                        @media screen and (max-width: 1600px) {
                            margin: 10px 0;
                        }
                        .btn-box {
                            display: inline-block;
                            width: 150px;
                            height: 40px;
                            font-size: 16px;
                            line-height: 40px;
                            border-radius: 40px;
                            text-align: center;
                            color: #fff;
                            background-color: $onColor;
                            @media screen and (max-width: 1600px) {
                                width: 100px;
                                height: 30px;
                                font-size: 14px;
                                line-height: 30px;
                                border-radius: 30px;
                            }
                        }
                    }
                }
                .dsc-hover {
                    position: absolute;
                    bottom: 0px;
                    height: auto;
                    display: none;
                    left: 50%;
                    transform: translateX(-50%);
                    background: url(../images/qldtbk_03.png) right top;
                    background-repeat: no-repeat !important;
                    //background-size: cover !important;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    @media screen and (max-width: 1600px) {
                        margin-top: -40px;
                    }
                    @media screen and (max-width: 1000px) {
                        display: none !important;
                    }
                    .title {
                        color: #fff;
                    }
                    .bottom-box {
                        .time {
                            color: #fff;
                        }
                        .lx {
                            .same {
                                background-color: #107773;
                                color: #fff;
                            }
                        }
                    }

                    .btn {
                        display: block;
                    }
                }
            }
        }

    }
}
