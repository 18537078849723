.edustion {
    background-color: #f5f5f5;
    padding: px2rem(116px) 0 0;
    .edustion-container {
        background-color: #fff;
        padding: px2rem(36px) 0 px2rem(110px);
        border-radius: 15px;
        .bk-img-edustion {
            background: url(../images/edustion2.png) left bottom;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            @media screen and (max-width: 1240px) {
                background: none;
            }
        }
    }
}
.edustion-title {
    position: relative;
    font-size: 30px;
    line-height: 1;
    font-weight: bold;
    color: #3c71b7;
    text-align: center;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 1240px) {
        font-size: 22px;
        padding: 0 px2rem(30px);
        line-height: 1.3;
    }
    p {
        position: relative;
        font-weight: bold;
        margin-bottom: 0;
    }
    p:before {
        position: absolute;
        content: '';
        top: 50%;
        left: -52px;
        transform: translateY(-50%);
        width: 32px;
        height: 19px;
        background: url(../images/edustionIcon1.png) center center;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        @media screen and (max-width: 1240px) {
            content: none;
        }
    }
    p:after {
        position: absolute;
        content: '';
        top: 50%;
        right: -52px;
        transform: translateY(-50%);
        width: 32px;
        height: 19px;
        background: url(../images/edustionIcon2.png) center center;
        background-repeat: no-repeat;
        background-position: 100% 100%;
        @media screen and (max-width: 1240px) {
            content: none;
        }
    }
}
.edustion-content {
    padding: px2rem(60px) px2rem(84px) px2rem(130px);
    box-sizing: border-box;
    font-size: 16px;
    line-height: 30px;
    color: #505050;
    @include articleReset(16px, 30px);
    @media screen and (max-width: 1240px) {
        font-size: 14px;
        line-height: 24px;
        @include articleReset(14px, 24px);
        padding: px2rem(60px) px2rem(84px);
    }
    .image-text {
        .edustion-text {
            margin-top: px2rem(42px);
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(30px);
            }
        }
        .edustion-image {
            margin-top: px2rem(92px);
            text-align: center;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(50px);
            }
        }
    }
}
.edustion-produceAdvantages {
    background-color: #3c71b7;
    padding: px2rem(40px) px2rem(50px) px2rem(76px);
    box-sizing: border-box;
    overflow: hidden;
    .title {
        .name {
            font-size: 32px;
            line-height: 1;
            font-weight: bold;
            color: #fff;
            text-align: center;
            @media screen and (max-width: 1240px) {
                font-size: 24px;
            }
        }
        .en {
            text-align: center;
            margin-top: px2rem(10px);
            font-size: 12px;
            line-height: 1;
            text-transform: uppercase;
            color: #fff;
            opacity: .5;
        }
    }
    .produceAdvantages-content {
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(13px) px2rem(-35px) px2rem(-32px);
        .content-box {
            display: flex;
            align-items: center;
            width: 50%;
            padding: px2rem(32px) px2rem(35px);
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                width: 100%;
            }
            .box {
                display: flex;
                align-items: center;
                .img {
                    @media screen and (max-width: 1240px) {
                        width: 60px;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .right-info {
                    margin-left: px2rem(10px);
                    flex: 1;
                    .b {
                        font-size: 20px;
                        line-height: 30px;
                        font-weight: bold;
                        color: #fff;
                        transition: all .3s;
                        @media screen and (max-width: 1240px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    .b:hover {
                        color: $baseColor;
                        transform: translateX(5px);
                    }
                    .s {
                        font-size: 16px;
                        line-height: 24px;
                        color: #fff;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                }
            }
            .box:hover {
                .right-info {
                    .b {
                        color: $onColor;
                        transform: translateX(5px);
                    }
                }
            }
        }
    }
}
.edustion-produceValue {
    padding: px2rem(92px) px2rem(40px) 0;
    box-sizing: border-box;
    overflow: hidden;
    @media screen and (max-width: 1240px) {
        padding: px2rem(80px) px2rem(40px) 0;
    }
    .produceValue-content {
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(61px) px2rem(-9px) px2rem(-9px);
        .content-box {

            width: 33.3%;
            padding: px2rem(9px);
            box-sizing: border-box;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .box {
                border: 2px solid #3c71b7;
                box-sizing: border-box;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                padding: 52px 45px;
                height: 100%;
                @media screen and (max-width: 1240px) {
                    padding: px2rem(30px);
                }
                .img {
                    text-align: center;
                    img {
                        max-width: 100%;
                        height: auto;
                        @media screen and (max-width: 1240px) {
                            max-width: none;
                            width: 100px;
                        }
                    }
                }
                .right-info {
                    margin-top: px2rem(26px);
                    flex: 1;
                    .b {
                        font-size: 22px;
                        line-height: 1;
                        color: #333;
                        text-align: center;
                        transition: all .3s;
                        @media screen and (max-width: 1240px) {
                            font-size: 16px;
                        }
                    }
                    .s {
                        margin-top: px2rem(22px);
                        font-size: 16px;
                        line-height: 24px;
                        color: #8f8f8f;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }
                }
            }
            .box:hover {
                .right-info {
                    .b {
                        color: $onColor;
                    }
                }
            }
        }
    }
}
.edustion-produceShow {
    padding: px2rem(95px) px2rem(40px) 0;
    @media screen and (max-width: 1240px) {
        padding: px2rem(80px) px2rem(40px) 0;
    }
    .edustion-image {
        text-align: center;
        margin-top: px2rem(10px);
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.edustion-appCase {
    margin-top: px2rem(116px);
    @media screen and (max-width: 1240px) {
        margin-top: px2rem(80px);
    }
    .appCase-content {
        position: relative;
        margin-top: px2rem(75px);
        padding: 0 px2rem(75px);
        @media screen and (max-width: 1240px) {
            margin-top: px2rem(40px);
            padding: 0 px2rem(30px);
        }
        .swiper-slide {
            display: flex;
            @media screen and (max-width: 750px) {
                display: block;
            }
            .left {
                width: (522 / 1050) * 100%;
                border-radius: 15px;
                @media screen and (max-width: 750px) {
                    width: 100%;
                }
                .img {
                    position: relative;
                    padding-bottom: (365 / 522) * 100%;
                    overflow: hidden;
                    img {
                        @include baseImg;
                        transition: all .3s;
                    }
                }
            }
            .right {
                position: relative;
                margin-left: -40px;
                flex: 1;
                border-radius: 15px;
                background-color: #3c71b7;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 16px;
                line-height: 30px;
                color: #fff;
                @media screen and (max-width: 750px) {
                    margin-left: 0;
                    margin-top: -40px;
                    font-size: 14px;
                    line-height: 24px;
                }
                .dsc {
                    max-width: 240px;
                    height: auto;
                    @media screen and (max-width: 750px) {
                        max-width: none;
                        padding: px2rem(30px);
                    }
                }
            }
        }
        .swiper-slide:hover {
            .left {
                .img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
        .same {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            width: 76px;
            height: 76px;
            border-radius: 50%;
            background-color: #e0dfdf;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 32px;
            color: #707070;
            outline: none;
            transition: all .3s;
            cursor: pointer;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                width: 40px;
                height: 40px;
                font-size: 16px;
            }
            @media screen and (max-width: 500px) {
                width: 30px;
                height: 30px;
                font-size: 12px;
            }
        }
        .same:hover {
            background-color: #3c71b7;
            color: #fff;
            border: none;
        }
        .appCase-next {
            right: (32 / 1200) *100%;
            @media screen and (max-width: 1240px) {
                right: 1.1%;
            }
        }
        .appCase-prev {
            left: (32 / 1200) *100%;
            transform: translateY(-50%) rotate(180deg);
            @media screen and (max-width: 1240px) {
                left: 1.1%;
            }
        }
    }
}