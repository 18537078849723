.about-info {
    padding: px2rem(60px) 0 px2rem(100px);
    background-color: #f5f5f5;
}
.info-container {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding: px2rem(78px) px2rem(64px) px2rem(65px);
    border-radius: 15px;
    .title-container {
        padding-bottom: px2rem(35px);
        border-bottom: 1px dashed #d0d0d0;
        .info-title {
            font-size: 32px;
            line-height: 45px;
            font-weight: bold;
            color: #333;
            text-align: center;
            box-sizing: border-box;
            padding: 0 79px;
            @media screen and (max-width: 1200px) {
                font-size: 26px;
                line-height: 30px;
                padding: 0 40px;
            }
            @media screen and (max-width: 500px) {
                font-size: 22px;
                line-height: 28px;
                padding: 0 px2rem(20px);
            }
        }
        .info-time {
            margin-top: 15px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 500px) {
                margin-top: 20px;
            }
            span {
                display: inline-block;
                font-size: 14px;
                line-height: 14px;
                color: #999;
                @media screen and (max-width: 1200px) {
                    font-size: 14px;
                    line-height: 14px;
                }
                @media screen and (max-width: 500px) {
                    font-size: 13px;
                    line-height: 13px;
                }
            }
            .num {
                margin-left: px2rem(30px);
            }

            .share {
                display: flex;
                margin-left: px2rem(30px);
                @media screen and (max-width: 558px) {
                    //margin-left: 0;
                    margin-top: 10px;
                }
                @media screen and (max-width: 558px) {
                    margin-left: 0px;

                }
                span {
                    line-height: 26px;
                }
                .bdsharebuttonbox {
                    margin-left: 10px;
                    a {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 50%;
                        text-indent: 0px;
                        background-image: none;
                        margin: 0 px2rem(10px) 0 0;
                        font-size: 14px;
                        color: #fff;
                        background-color: $baseColor;
                        text-align: center;
                        @media screen and (max-width: 750px) {
                            width: 22px;
                            height: 22px;
                            line-height: 22px;
                            font-size: 13px;
                        }
                    }
                }
            }
        }
    }
    .info-content {
        box-sizing: border-box;
        padding-top: px2rem(40px);
        padding-bottom: px2rem(40px);
        border-bottom: 1px dashed #d0d0d0;
        font-size: 16px;
        line-height: 35px;
        color: #666;
        @include articleReset(16px, 35px);
        @media screen and (max-width: 1200px) {
            font-size: 16px;
            line-height: 32px;
            @include articleReset(16px, 32px);
        }

        .img {
            margin-bottom: px2rem(30px);
            text-align: center;
        }
        .video {
            cursor: pointer;
            text-align: center;
            img {
                max-width: 100%;
                height: auto;
            }
        }

    }

    .info-turn {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin-top: px2rem(35px);
        .turn-left {
            width: calc(100% - 170px);
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            .prev,
            .next {
                a {
                    display: inline-block;
                    overflow: hidden;
                    font-size: 16px;
                    line-height: 45px;
                    height: 45px;
                    color: #000;
                    @media screen and (max-width: 500px) {
                        font-size: 13px;
                        line-height: 25px;
                        height: 25px;
                    }
                    .sp1 {
                        color: #333;
                    }
                }
            }
            .prev:hover,
            .next:hover {
                a {
                    .sp2 {
                        color: $onColor;
                    }
                }
            }
        }
        .turn-back {
            display: block;
            margin-left: 20px;
            width: 150px;
            height: 40px;
            background-color: $baseColor;
            text-align: center;
            border-radius: 40px;
            @media screen and (max-width: 500px) {
                margin: 10px auto 0;
                width: 120px;
                height: 30px;
                border-radius: 30px;
            }
            span {
                font-size: 14px;
                line-height: 40px;
                color: #fff;
                @media screen and (max-width: 500px) {
                    font-size: 13px;
                    line-height: 30px;
                }
            }
        }
        .turn-back:hover {
            background-color: $onColor;

        }
    }
}