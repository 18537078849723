.about-juntao {
    padding: px2rem(60px) 0 px2rem(70px);
    background-color: #f5f5f5;
    box-sizing: border-box;
    .juntao-content {
        margin-top: px2rem(40px);
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1000px) {
            display: block;
        }
        .content-left {
            width: (660 / 1200) * 100%;
            padding: 46px 55px 52px;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                padding: px2rem(60px) px2rem(30px) px2rem(30px);
            }
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .logo {
                img {
                    max-width: 100%;
                    height: auto;
                    @media screen and (max-width: 1000px) {
                        width: px2rem(315px);
                        min-width: 200px;
                    }
                }
            }
            .article {
                margin-top: px2rem(20px);
                font-size: 16px;
                line-height: 35px;
                color: #888;
                @include articleReset(16px, 35px);
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 28px;
                    @include articleReset(14px, 28px);
                }
            }
        }
        .content-right {
            flex: 1;
            width: (540 / 1200) * 100%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .video {
                position: relative;
                width: 100%;
                padding-bottom: (680 / 540) * 100%;
                border-radius: 15px;
                overflow: hidden;
                cursor: pointer;
                //video {
                //    position: absolute;
                //    top: 50%;
                //    left: 50%;
                //    transform: translate(-50%, -50%);
                //    width: 100%;
                //    height: 100%;
                //    outline: none;
                //}
                img {
                    @include baseImg;
                }
            }
        }
    }
}
.juntao-advantage {
    padding: px2rem(60px) 0;
    background-color: #fff;
    overflow: hidden;
    .advantage-content {
        margin-top: px2rem(40px);
        overflow: hidden;
    }
    .content-same {
        display: flex;
        flex-wrap: wrap;
        margin: px2rem(-10px);
        .content-box {
            width: 33.3%;
            padding: px2rem(10px);
            box-sizing: border-box;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            .box {
                width: 100%;
                cursor: pointer;
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (290 / 385) * 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    img {
                        @include baseImg;
                        transition: all .3s;
                    }
                }
                .dsc-box {
                    position: relative;
                    z-index: 1;
                    margin-top: px2rem(-30px);
                    background-color: $onColor;
                    border-radius: 15px;
                    overflow: hidden;
                    padding: 30px 30px 23px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        padding: px2rem(30px) px2rem(20px);
                        margin-top: -30px;
                    }
                    p:nth-of-type(1) {
                        font-size: 20px;
                        line-height: 1;
                        color: #fff;
                        font-weight: bold;
                        transition: all .3s;
                        @media screen and (max-width: 1240px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 1000px) {
                            height: 32px;
                        }
                        @media screen and (max-width: 500px) {
                            height: auto;
                        }
                    }
                    p:nth-of-type(2) {
                        font-size: 16px;
                        line-height: 30px;
                        color: #fff;
                        margin-top: px2rem(8px);
                        overflow: hidden;
                        @media screen and (max-width: 1240px) {
                            font-size: 13px;
                            line-height: 18px;
                        }
                        @media screen and (max-width: 1000px) {
                            height: 36px;
                        }
                        @media screen and (max-width: 500px) {
                            height: auto;
                        }
                    }
                }
            }
            .box:hover {
                .img {
                    img {
                        transform: scale(1.1);
                    }
                }
                .dsc-box {
                    p:nth-of-type(1) {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .content-bottom {
        margin-top: px2rem(10px);
        .content-box {
            width: 50%;
            @media screen and (max-width: 500px) {
                width: 100%;
            }
            .box {
                .dsc-box {
                    margin-top: px2rem(-40px);
                    @media screen and (max-width: 1240px) {
                        margin-top: -30px;
                    }
                }
            }
        }

    }
}
.resources {
    padding: px2rem(60px) 0;
    background-color: #f5f5f5;
    .resources-content {
        margin-top: px2rem(40px);
        .dsc {
            font-size: 16px;
            line-height: 35px;
            color: #888;
            padding: 0 px2rem(30px);
            box-sizing: border-box;
            @include articleReset(16px, 35px);
            @media screen and (max-width: 1240px) {
                font-size: 14px;
                line-height: 28px;
                @include articleReset(14px, 28px);
            }
        }
    }
    .resources-teacher {
        position: relative;
        margin-top: px2rem(17px);
        padding: 0 80px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: 0 px2rem(60px);
        }
        .box {
            border-radius: 15px;
            overflow: hidden;
            padding: 36px 38.5px 33px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #fff;
            cursor: pointer;
            @media screen and (max-width: 1240px) {
                padding: px2rem(30px);
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (208 / 208) * 100%;
                overflow: hidden;
                img {
                    @include baseImg;
                }
                .circle {
                    position: absolute;
                    top: 20px;
                    right: 0;
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    border-radius: 50%;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    background-color: $onColor;
                    transition: all .3s;
                    @media screen and (max-width: 500px) {
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 12px;
                    }
                }
            }
            .name {
                font-size: 24px;
                line-height: 1;
                font-weight: bold;
                color: #333;
                margin-top: px2rem(25px);
                @media screen and (max-width: 1240px) {
                    font-size: 18px;
                }
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
            }
            .dsc {
                margin-top: px2rem(16px);
                font-size: 16px;
                line-height: 35px;
                text-align: center;
                color: #888;
                height: 100%;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 22px;
                }
                @media screen and (max-width: 500px) {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .box:hover {
            .name {
                color: $onColor;
            }
        }
        .same {
            position: absolute;
            z-index: 2;
            top: 50%;
            transform: translateY(-50%);
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: #000;
            border: 1px solid #ccc;
            outline: none;
            transition: all .3s;
            cursor: pointer;
            @media screen and (max-width: 1240px) {
                width: 40px;
                height: 40px;
            }
            @media screen and (max-width: 500px) {
                width: 25px;
                height: 25px;
            }
        }
        .same:hover {
            background-color: $onColor;
        }
        .teacher-next {
            right: 0;
        }
        .teacher-prev {
            left: 0;
            transform: translateY(-50%) rotate(180deg);
        }
    }
}
.franchise-bk {
    width: 100%;
    height: px2rem(603px);
}
.franchise {
    background-color: #f5f5f5;
    padding: px2rem(70px) 0;
    .wp1200 {
        position: relative;
        z-index: 3;
        margin-top: px2rem(-603px);
        .tree-title {
            .cn {
                color: #fff;
            }
            .en {
                color: #fff;
            }
        }
        .franchise-content {
            margin-top: px2rem(40px);
            border-radius: 15px;
            overflow: hidden;
            background-color: #fff;
            .title {
                background-color: $onColor;
                text-align: center;
                font-size: 28px;
                line-height: 35px;
                color: #fff;
                font-weight: bold;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 90px;
                @media screen and (max-width: 1240px) {
                    height: px2rem(110px);
                    font-size: 20px;
                    line-height: 28px;
                }
            }
            .content-list {
                padding: 40px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    padding: px2rem(30px);
                }
                .list-box {
                    display: flex;
                    padding-bottom: 26px;
                    margin-bottom: 30px;
                    box-sizing: border-box;
                    border-bottom: 1px dashed #d4d4d4;
                    @media screen and (max-width: 1240px) {
                        padding-bottom: px2rem(20px);
                        margin-bottom: px2rem(26px);
                    }
                    .numer-box {
                        width: 80px;
                        height: 80px;
                        line-height: 80px;
                        border-radius: 50%;
                        font-size: 32px;
                        font-family: Arial;
                        font-weight: bold;
                        color: #fff;
                        text-align: center;
                        background-color: $onColor;
                        transition: all .3s;
                        @media screen and (max-width: 1240px) {
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            font-size: 26px;
                        }
                        @media screen and (max-width: 500px) {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            font-size: 20px;
                        }
                    }
                    .dsc-box {
                        flex: 1;
                        margin-left: px2rem(20px);
                        padding-top: px2rem(12px);
                        box-sizing: border-box;
                        .question {
                            font-size: 20px;
                            line-height: 1;
                            font-weight: bold;
                            color: #333;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                            }
                        }
                        .answer {
                            margin-top: px2rem(16px);
                            font-size: 16px;
                            line-height: 24px;
                            color: #888;
                            @media screen and (max-width: 1240px) {
                                font-size: 14px;
                                line-height: 26px;
                            }
                        }
                    }
                }
                .list-box:hover {
                    //.numer-box {
                    //    transform: rotateY(360deg);
                    //}
                    .dsc-box {
                        .question {
                            transform: translateX(10px);
                            color: $onColor;
                        }
                    }
                }
                .list-box:nth-last-of-type(1) {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}
.about-support {
    padding: px2rem(60px) 0;
    .support-content {
        margin-top: px2rem(40px);
        overflow: hidden;
        .content-same {
            display: flex;
            flex-wrap: wrap;
            margin: px2rem(-10px);
            .content-box {
                width: 33.3%;
                padding: px2rem(10px);
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    width: 50%;
                }
                .box {
                    width: 100%;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (290 / 385) * 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .dsc-box {
                        position: relative;
                        z-index: 1;
                        margin-top: -30px;
                        background: url(../images/juntao6.png) top left;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        padding: 40px 35px 0;
                        height: 210px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            margin-top: -20px;
                            padding: px2rem(30px);
                            height: auto;
                        }
                        p:nth-of-type(1) {
                            font-size: 24px;
                            line-height: 1;
                            color: #fff;
                            font-weight: bold;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: 18px;
                            }
                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                            }
                        }
                        p:nth-of-type(2) {
                            font-size: 14px;
                            line-height: 26px;
                            height: 130px;
                            overflow: hidden;
                            color: #fff;
                            margin-top: 15px;
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                                height: 72px;
                            }
                        }
                    }
                }
                .box:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .dsc-box {
                        p:nth-of-type(1) {
                            transform: translateX(10px);
                        }
                    }
                }
            }
        }
    }
}
.about-base {
    padding: px2rem(60px) 0;
    background-color: #f5f5f5;
    .base-content {
        margin-top: px2rem(30px);
        font-size: 16px;
        line-height: 35px;
        color: #888;
        @include articleReset(16px, 35px);
        @media screen and (max-width: 1240px) {
            font-size: 14px;
            line-height: 28px;
            @include articleReset(14px, 28px);
        }
        .img {
            text-align: center;
        }
    }
}
.about-process {
    padding: px2rem(60px) 0 px2rem(70px);
    .process-content {
        margin-top: px2rem(40px);
        .selector {
            display: flex;
            justify-content: center;
            .box {
                width: 110px;
                height: 35px;
                color: #fff;
                font-size: 16px;
                line-height: 35px;
                text-align: center;
                background-color: #2fa8e1;
                border-radius: 35px;
                margin-right: px2rem(20px);
                cursor: pointer;
                //@media screen and (max-width: 1240px) {
                //    width: 80px;
                //    height: 25px;
                //    font-size: 14px;
                //    line-height: 25px;
                //    border-radius: 25px;
                //}
            }
            .on {
                background-color: $onColor;
            }
            .box:nth-last-of-type(1) {
                margin-right: 0px;
            }
        }
        .content-container {
            position: relative;
            display: none;
            margin-top: px2rem(50px);
            padding-right: 54px;
            box-sizing: border-box;
            background: url(../images/juntao7.png) center right;
            background-repeat: no-repeat;
            overflow: hidden;
            animation-name: b2t;
            -webkit-animation-name: b2t;
            -ms-animation-name: b2t;
            animation-timing-function: linear;
            -webkit-animation-timing-function: linear;
            -ms-animation-timing-function: linear;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            -ms-animation-fill-mode: forwards;
            animation-duration: .5s;
            -webkit-animation-duration: .5s;
            -ms-animation-duration: .5s;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(40px);
            }
            @media screen and (max-width: 750px) {
                background: none;
                padding-right: 0;
            }
            .content-box {
                position: relative;
                z-index: 2;
                display: flex;
                flex-wrap: wrap;
                margin: -11px -25px;
                @media screen and (max-width: 1240px) {
                    margin: -11px;
                }
                .box {
                    width: 33.3%;
                    padding: 11px 25px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        padding: 11px;
                    }

                    @media screen and (max-width: 750px) {
                        width: 100%;
                    }
                    .box-bk {
                        background-color: #f5f5f5;
                        display: flex;
                        align-items: center;
                        height: 120px;
                        border-radius: 120px;
                        padding: 20px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            height: 100px;
                            border-radius: 100px;
                            padding: 10px;
                        }
                        @media screen and (max-width: 750px) {
                            height: auto;
                            padding: 20px;
                        }
                        .circle-num {
                            height: 70px;
                            width: 70px;
                            border-radius: 50%;
                            background-color: #999;
                            color: #fff;
                            font-size: 28px;
                            line-height: 70px;
                            text-align: center;
                            font-family: Arial;
                            font-weight: bold;
                            @media screen and (max-width: 1240px) {
                                height: 50px;
                                width: 50px;
                                font-size: 18px;
                                line-height: 50px;
                            }
                        }
                        .info {
                            flex: 1;
                            margin-left: px2rem(13px);

                            .title {
                                font-size: 16px;
                                line-height: 1;
                                color: #333;
                                @media screen and (max-width: 1240px) {
                                    font-size: 15px;
                                }
                                @media screen and (max-width: 750px) {
                                    font-size: 18px;
                                }
                            }
                            .dsc {
                                margin-top: 8px;
                                font-size: 14px;
                                line-height: 28px;
                                color: #666;
                                overflow: hidden;
                                @media screen and (max-width: 1240px) {
                                    font-size: 12px;
                                    line-height: 16px;
                                    height: 16px;
                                }
                                @media screen and (max-width: 750px) {
                                    font-size: 14px;
                                    line-height: 20px;
                                    height: 40px;
                                }
                            }
                        }
                    }
                }
                .box:nth-of-type(1) {
                    order: 1;
                }
                .box:nth-of-type(2) {
                    order: 2;
                }
                .box:nth-of-type(3) {
                    order: 3;
                }
                .box:nth-of-type(4) {
                    order: 6;
                    @media screen and (max-width: 750px) {
                        order: 4;
                    }
                }
                .box:nth-of-type(5) {
                    order: 5;
                }
                .box:nth-of-type(6) {
                    order: 4;
                    @media screen and (max-width: 750px) {
                        order: 6;
                    }
                }
            }
        }
        .content-container:before {
            position: absolute;
            content: none;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 4px;
            height: 100%;
            background-color: $onColor;
            @media screen and (max-width: 750px) {
                content: '';
            }
        }
        .active {
            display: block;
        }
    }
}
.about-application {
    background-repeat: no-repeat;
    padding: px2rem(60px) 0 px2rem(130px);
    .tree-title {
        .cn,
        .en {
            color: #fff;
        }
    }
}
.application-content {
    margin-top: px2rem(40px);
    .feedback-main {
        overflow: hidden;
        input,
        textarea,
        select {
            display: block;
            border: none;
            outline: none;
            background: none;
            color: #666;
            width: 100%;
            font-size: 14px;
            list-style: none;
        }
        .input-group50 {
            display: flex;
            flex-wrap: wrap;
            margin: 0 px2rem(-15px);
            li {
                margin-bottom: 15px;
                padding: 0 px2rem(15px);
                width: 50%;
                box-sizing: border-box;
            }
        }
        .input-box,
        .select-box {
            display: flex;
            height: 50px;
            line-height: 50px;
            background: #f4f4f4;
        }
        .input-box {
            padding: 0 px2rem(20px);
            border-radius: 5px;
        }
        .select-box {
            padding-left: px2rem(20px);
            border-radius: 5px;
        }
        .input {
            flex: 1;
            input {
                line-height: 50px;
            }
        }
        .select {
            flex: 1;
            position: relative;
            &:after {
                content: '\e635';
                position: absolute;
                right: px2rem(16px);
                top: 0;
                font-size: 20px;
                color: #464646;
                font-family: 'iconfont';
            }
            select {
                line-height: 50px;
                height: 50px;
                appearance: none;
                -webkit-appearance: none;
                -moz-appearance: none;
            }
            select::-ms-expand { display: none; }
        }
        .textarea-box {
            margin-bottom: 20px;
            padding: 12px 20px;
            background: #f4f4f4;
            line-height: 24px;
            textarea {
                resize: none;
                line-height: 24px;
            }
        }
        .qrcode-box {
            display: flex;
            align-items: center;
            .input-box {
                width: (380 / 593) * 100%;
            }
        }
        .qrcode-img {
            flex: 1;
            margin-left: px2rem(16px);
            img {
                width: auto;
                max-height: 50px;
            }
        }
        .input-text {
            color: #666;
            font-size: 14px;
            em {
                font-style: normal;
                color: #d21e1e;
                font-size: 12px;
            }
        }
        button {
            width: 160px;
            height: 45px;
            line-height: 45px;
            text-align: center;
            font-size: 16px;
            color: #fff;
            background: $onColor;
            border-radius: 45px;
            border: none;
            outline: none;
            cursor: pointer;
            transition: all .3s;
            &:hover {
                opacity: .85;
            }
        }
        .yz {
            border-radius: 5px;
            overflow: hidden;
            margin-bottom: px2rem(15px);
            @media screen and (max-width: 640px) {
                margin-bottom: 15px;
            }
        }
        @media screen and (max-width: 640px) {
            font-size: 13px;
            .input-group50 {
                li {
                    margin-bottom: 15px;
                    width: 100%;
                }
            }
            .input-box,
            .select-box {
                height: 40px;
                line-height: 40px;
            }
            .input {
                input {
                    line-height: 40px;
                }
            }
            .select {
                select {
                    line-height: 40px;
                    height: 40px;
                }
            }
            .textarea-box {
                margin-bottom: 15px;
                padding: 8px 15px;
            }
            .qrcode-box {
                .input-box {
                    flex: 1;
                    width: auto;
                }
            }
            .qrcode-img {
                flex: 0 0 90px;
                img {
                    max-width: 100%;
                    max-height: 40px;
                }
            }
            button {
                float: none;
                width: 100%;
                height: 40px;
                line-height: 40px;
                font-size: 15px;
            }
        }
    }
}
@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}

