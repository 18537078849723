.footer {
    background-color: #24292f;
    padding-top: px2rem(80px);
    padding-bottom: px2rem(60px);
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        padding: px2rem(70px) 0;
    }
    .index-footer {
        display: flex;
        justify-content: space-between;
        .left {
            width: (320 / 1600) * 100%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .lxfs {
                display: flex;
                p {
                    font-size: px2rem(50px);
                    line-height: px2rem(50px);
                    font-family: Arial;
                    color: $baseColor;
                    @media screen and (max-width: 1600px) {
                        font-size: 38px;
                        line-height: 38px;
                    }
                    @media screen and (max-width: 1000px) {
                        font-size: px2rem(50px);
                        line-height: px2rem(50px);
                    }
                }
            }
            .yx {
                margin-top: 32px;
                @media screen and (max-width: 1600px) {
                    margin-top: 20px;
                }
                @media screen and (max-width: 1000px) {
                    width: 100%;
                    margin-top: px2rem(20px);
                }
                p {
                    font-size: 14px;
                    line-height: 30px;
                    color: #fff;
                    @media screen and (max-width: 1000px) {
                        font-size: px2rem(22px);
                        line-height: px2rem(45px);
                    }
                }
            }
            .ckdt {
                margin-top: 22px;
                @media screen and (max-width: 1000px) {
                    margin-top: px2rem(18px);
                }
                a {
                    display: inline-block;
                    width: 150px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 40px;
                    background-color: $baseColor;
                    color: #fff;
                    text-align: center;
                    transition: all .3s;
                    font-size: 14px;
                    @media screen and (max-width: 1600px) {
                        width: 100px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 12px;
                        border-radius: 30px;
                    }
                }
                a:hover {
                    background-color: $onColor;
                }
            }
        }
        .right {
            width: (1025 / 1600) * 100%;
            @media screen and (max-width: 1000px) {
                display: none;
            }
            .footer-nav {
                display: flex;
                dl {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-left: 1px solid #2f343a;
                    text-align: center;
                    dt {
                        position: relative;
                        a {
                            font-size: 16px;
                            line-height: 16px;
                            color: #fbfbfb;
                        }
                    }
                    dt:before {
                        position: absolute;
                        content: '';
                        width: 10px;
                        height: 1px;
                        background-color: $baseColor;
                        bottom: -10px;
                        transition: all .3s;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    dt:hover:before {
                        width: 100%;
                    }
                    dd {
                        margin-top: 18px;
                        a {
                            font-size: 14px;
                            line-height: 14px;
                            color: rgba(255, 255, 255, .6);
                        }
                        a:hover {
                            color: #fff;
                            text-decoration: underline;
                        }
                    }
                    dd:nth-of-type(1) {
                        margin-top: 32px;
                    }
                }
                dl:nth-of-type(1) {
                    border-left: none;
                }
            }
        }
    }
}
.footer-bottom {
    border-top: 1px solid #3a3f44;
    background-color: #24292f;
    text-align: center;
    .bottom-content {
        height: px2rem(90px);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 1000px) {
            height: auto;
            display: block;
            padding: px2rem(50px) 0 px2rem(60px);
        }
        .content-left {
            p {
                display: flex;
                justify-content: center;
                font-size: px2rem(14px);
                line-height: px2rem(32px);
                color: rgba(255, 255, 255, 1);
                @media screen and (max-width: 1000px) {
                    display: block;
                }
                @media screen and (max-width: 500px) {
                    font-size: 12px;
                    line-height: 18px;
                }
                span {
                    display: block;
                    font-size: px2rem(14px);
                    line-height: px2rem(32px);
                    color: rgba(255, 255, 255, 1);
                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
                a {
                    display: block;
                    margin-left: px2rem(10px);
                    font-size: px2rem(14px);
                    line-height: px2rem(32px);
                    color: rgba(255, 255, 255, 1);
                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                        line-height: 18px;
                    }
                    img {
                        vertical-align: text-top;
                        margin-right: 5px;
                    }
                }
                a:hover {
                    text-decoration: underline;
                }
            }
        }
        .content-right {
            display: flex;
            @media screen and (max-width: 1000px) {
                justify-content: center;
                margin-top: px2rem(24px);
            }
            .icon-box {
                position: relative;
                display: block;
                cursor: pointer;
                width: 48px;
                height: 48px;
                border-radius: 50%;
                font-size: 24px;
                color: $baseColor;
                text-align: center;
                line-height: 48px;
                background-color: #24292f;
                background-image: none;
                text-indent: 0px;
                margin: 0;
                @media screen and (max-width: 500px) {
                    width: 38px;
                    height: 38px;
                    font-size: 20px;
                    line-height: 38px;
                }
                .code {
                    position: absolute;
                    z-index: 2;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 68px;
                    display: none;
                    padding: 10px;
                    background-color: #fff;
                    box-sizing: border-box;
                    border-radius: 15px;
                    transition: all .3s;
                    overflow: hidden;
                    @media screen and (max-width: 1800px) {
                        flex-wrap: wrap;
                    }
                    @media screen and (max-width: 500px) {
                        bottom: 58px;
                    }
                    .code-box {
                        width: 90px;
                        margin-right: 10px;
                        @media screen and (max-width: 1800px) {
                            margin-right: 0px;
                            margin-bottom: 10px;
                        }
                        @media screen and (max-width: 500px) {
                            width: 70px;
                        }
                        img {
                            display: block;
                            max-width: 100%;
                            height: auto;
                        }
                        p {
                            margin-top: 5px;
                            display: block;
                            font-size: 14px;
                            line-height: 14px;
                            color: #333;
                        }
                    }
                    .code-box:nth-last-of-type(1) {
                        margin-right: 0px;
                        @media screen and (max-width: 1800px) {
                            margin-bottom: 10px;
                        }
                    }
                }
                .code:before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    bottom: -15px;
                    border-width: 6px 11px 6px 0;
                    right: 50%;
                    -webkit-transform: translateX(50%);
                    -moz-transform: translateX(50%);
                    -ms-transform: translateX(50%);
                    transform: translateX(50%);
                    border-top: 15px solid #fff;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                }
                .code-app {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    right: -220px;
                    bottom: 68px;
                    padding: 20px;
                    background-color: #fff;
                    box-sizing: border-box;
                    border-radius: 15px;
                    transition: all .3s;
                    @media screen and (max-width: 1800px) {
                        flex-wrap: wrap;
                        right: 50%;
                        transform: translateX(50%);
                        padding: 10px;
                    }
                    @media screen and (max-width: 500px) {
                        bottom: 58px;
                        right: auto;
                        left: -20px;
                        transform: none;
                    }
                    .code-box {
                        margin-right: 50px;
                        @media screen and (max-width: 1800px) {
                            margin-right: 0px;
                            margin-bottom: px2rem(30px);
                        }
                        .box-name {
                            font-size: 14px;
                            line-height: 1;
                            color: #333;
                            text-align: left;
                            font-weight: bold;
                            white-space: nowrap;
                        }
                        .box-img {
                            display: flex;
                            margin-top: px2rem(15px);
                            .box {
                                margin-right: px2rem(15px);
                                img {
                                    width: 79px;
                                    height: auto;
                                }
                                p {
                                    margin-top: px2rem(6px);
                                    text-align: center;
                                    font-size: 14px;
                                    line-height: 1;
                                    color: #333;
                                }
                            }
                            .box:nth-last-of-type(1) {
                                margin-right: 0;
                            }
                        }
                    }
                    .code-box:nth-last-of-type(1) {
                        margin-right: 0;
                        @media screen and (max-width: 1800px) {
                            margin-bottom: 0;
                        }
                    }
                }
                .code-app:before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    bottom: -15px;
                    border-width: 6px 11px 6px 0;
                    right: 245px;
                    -webkit-transform: translateX(50%);
                    -moz-transform: translateX(50%);
                    -ms-transform: translateX(50%);
                    transform: translateX(50%);
                    border-top: 15px solid #fff;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    @media screen and (max-width: 1800px) {
                        right: 50%;
                    }
                    @media screen and (max-width: 500px) {
                        right: auto;
                        left: 10px;

                    }
                }
            }
            .icon-box:hover {
                background-color: $baseColor;
                color: #fff;
                .code {
                    display: flex;
                }
                .code-app {
                    display: flex;
                }
            }
        }
    }
}
.right-bar {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 38px;
    text-align: center;
    z-index: 1000;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    li {
        position: relative;
        margin-top: 4px;
        padding: px2rem(16px) 23px px2rem(11px);
        background-color: #f5f5f5;
        font-size: 16px;
        border: 1px solid #e5e5e5;
        border-radius: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        cursor: pointer;
        .box {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .icon {
                font-size: 26px;
                color: $baseColor;
            }
            p {
                margin-top: 10px;
                color: #333;
                font-size: 16px;
                line-height: 16px;
            }
        }
        .box-hover {
            transition: all .3s;
            display: none;
            position: absolute;
            top: 0;
            right: 100px;
            background-color: #fff;
            border: 1px solid #e5e5e5;
            padding: 5px;
            border-radius: 10px;
            box-sizing: border-box;
            text-align: center;
            .code-box {
                width: 90px;
                text-align: center;
                margin-top: 10px;
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
                p {
                    display: block;
                    font-size: 14px;
                    line-height: 14px;
                    color: #333;
                }
            }
            .code-box:nth-of-type(1) {
                margin-top: 0;
            }
        }
        .box-hover:before {
            position: absolute;
            z-index: 9;
            content: '';
            top: 30px;
            right: -14px;
            border-width: 12px 0 12px 14px;
            border-style: solid;
            border-color: transparent transparent transparent #fff;
        }
        .box-hover:after {
            position: absolute;
            z-index: 8;
            content: '';
            top: 30px;
            right: -15px;
            border-width: 12px 0 12px 14px;
            border-style: solid;
            border-color: transparent transparent transparent #e5e5e5;
        }
    }
    .douyin {
        .box-hover {
            p {
                margin-top: 5px;
            }
        }
    }
    li:hover {
        border: 1px solid $baseColor;
        background-color: $baseColor;
        .box {
            .icon {
                color: #fff;
            }
            p {
                color: #fff;
            }
        }
        .box-hover {
            display: block;
        }
    }
}