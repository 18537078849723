.uweb-search {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto px2rem(100px);
    padding-top: px2rem(184px);
    //搜索框
    &-input-con {
        float: left;
        width: 50%;
        box-sizing: border-box;
        padding-right: 25px;
    }
    &-select-con {
        float: left;
        width: 37%;
        box-sizing: border-box;
        padding-right: 25px;
    }
    @media screen and (max-width: 750px) {
        &-input-con,
        &-select-con {
            float: none;
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
        }
    }
    &-top {
        position: relative;
        margin-bottom: 40px;
        height: 44px;
        width: 100%;
        &-s {
            .uweb-search-input-con {
                float: left;
                width: 87%;
            }
        }
        @media screen and (max-width: 750px) {
            height: auto;
            &-s {
                .uweb-search-input-con {
                    width: 100%;
                }
            }
        }
        &-text {
            float: left;
            width: 80px;
            font-size: 14px;
            color: #666;
            line-height: 44px;
        }
        &-input {
            margin: 0 0 0 80px;
            height: 44px;
            border: 1px solid #e9e9e9;
            input {
                width: 100%;
                height: 100%;
                border: 0;
                font-size: 14px;
                padding: 0 20px;
            }
        }
        &-button {
            float: left;
            width: 13%;
            height: 46px;
            background: #c7000b;
            cursor: pointer;
            color: #fff;
            font-size: 14px;
            line-height: 46px;
            text-align: center;
            appearance: none;
            border: none;
            padding: 0;
            &:hover {
                opacity: .7;
            }
            @media screen and (max-width: 750px) {
                float: none;
                width: 100%;
            }
        }
        //搜索框 搜索类型
        &-input2 {
            box-sizing: border-box;
            margin-left: 80px;
            height: 44px;
            border: 1px solid #e9e9e9;
            input {
                box-sizing: border-box;
                width: 100%;
                height: 100%;
                border: 0;
                font-size: 14px;
                padding: 0 20px;
            }
        }
        &-text1 {
            float: left;
            width: 70px;
            font-size: 14px;
            color: #666;
            line-height: 44px;
        }
        &-select {
            position: relative;
            margin-left: 70px;
            height: 44px;
            border: 1px solid #e9e9e9;
            font-size: 14px;
            color: #999;
            &:after {
                position: absolute;
                right: 12px;
                top: 50%;
                transform: translate(0, -50%);
                content: "";
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 6px solid #999;
            }
            select {
                position: relative;
                z-index: 10;
                width: 100%;
                height: 100% !important;
                border: 0px;
                padding: 0 22px;
                box-sizing: border-box;
            }
            .customSelect {
                width: 100%;
                height: 100%;
                line-height: 42px;
                padding: 0 22px;
                box-sizing: border-box;
            }
            @media screen and (max-width: 1200px) {
                float: none;
                flex: 1;
                width: auto;
            }
        }
    }
    //搜索内容
    &-content {
        margin: 40px -12px;
        font-size: 0;
        li {
            display: inline-block;
            vertical-align: top;
            padding: 0 12px;
            box-sizing: border-box;
            width: 50%;
            height: 156px;
            margin-bottom: 30px;
            a {
                display: block;
                width: 100%;
                height: 100%;
                border: 1px solid #e9e9e9;
                box-sizing: border-box;
                padding: 25px 40px 30px;
                transition: all .3s ease;
                &:hover {
                    background: #f1f1f1;
                    .text {
                        color: #c7000b;
                    }
                    .btn {
                        background: #c7000b;
                    }
                }
                .text {
                    font-size: 16px;
                    font-weight: bold;
                    color: #666;
                    transition: all .3s ease;
                    line-height: 22px;
                    max-height: 44px;
                    overflow: hidden;
                }
                .time {
                    margin-top: 5px;
                    font-size: 14px;
                    font-family: "Arial";
                    color: #999;
                }
                .btn {
                    margin-top: 20px;
                    width: 95px;
                    height: 28px;
                    background: #999;
                    font-size: 14px;
                    color: #fff;
                    line-height: 28px;
                    text-align: center;
                    transition: all .3s ease;
                }
            }
        }
        @media screen and (max-width: 1200px) {
            margin-right: 0;
            margin-left: 0;
            li {
                &:nth-child(2n+1) {
                    padding: 0 12px 0 0;
                }
                &:nth-child(2n) {
                    padding: 0 0 0 12px;
                }
            }
        }
        @media screen and (max-width: 480px) {
            li {
                float: none;
                width: 100%;
                height: auto;
                &:nth-child(2n+1),
                &:nth-child(2n) {
                    padding: 0;
                }
                a {
                    .text {
                        max-height: none;
                    }
                }
            }
        }
    }
}
.uweb-search-tip-con {
    margin: 0px auto;
    width: 1200px;
    max-width: 100%;
    background: #fff;
    box-sizing: border-box;
    padding: px2rem(240px) px2rem(100px) px2rem(100px);
    text-align: center;
    .title {
        font-size: 24px;
        color: #333;
        margin-bottom: 20px;
    }
    .tips {
        font-size: 14px;
        color: orange;
        font-weight: bold;
        line-height: 28px;
        width: 900px;
        margin: 0 auto 10px;
        max-width: 100%;
    }
    .p {
        font-size: 14px;
        color: #333;
        line-height: 28px;
        margin-bottom: 10px;
    }
    .num {
        color: red;
        padding: 0 3px;
        text-decoration: underline;
    }
    .tip-btn {
        display: block;
        width: 140px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        color: #fff;
        background: #999;
        border: none;
        appearance: none;
        margin: 0 auto;
        cursor: pointer;
        &:hover {
            background: lighten(#999, 10%);
        }
    }
}