.about-values {
    padding: px2rem(70px) 0;
    background: url(../images/values2.png) bottom left;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    .values-content {
        margin-top: px2rem(40px);
        overflow: hidden;
        .img {
            position: relative;
            width: 100%;
            padding-bottom: (360 / 1200) * 100%;
            overflow: hidden;
            border-radius: 15px;
            img {
                @include baseImg;
                transition: all .3s;
            }
        }
        .img:hover {
            img {
                transform: scale(1.1);
            }
        }
        .content-first {
            display: flex;
            flex-wrap: wrap;
            margin: 7px -7px -7px;
            height: 280px;
            border-radius: 15px;
            overflow: hidden;
            @media screen and (max-width: 1240px) {
                height: auto;
            }
            .first-box {
                height: 100%;
                width: 25%;
                padding: 7px;
                box-sizing: border-box;

                @media screen and (max-width: 1240px) {
                    height: auto;
                }
                @media screen and (max-width: 1000px) {
                    width: 50%;
                }
                .box-bk {
                    width: 100%;
                    height: 100%;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 50px 20px 0;
                    box-sizing: border-box;
                    cursor: pointer;
                    border-radius: 15px;
                    @media screen and (max-width: 1240px) {
                        padding: 30px 20px;
                    }
                    .icon {
                        font-size: 50px;
                        line-height: 50px;
                        color: $baseColor;
                        @media screen and (max-width: 1240px) {
                            font-size: 40px;
                            line-height: 40px;
                        }
                    }
                    .name {
                        margin-top: 40px;
                        font-size: 20px;
                        line-height: 1;
                        color: #333;
                        @media screen and (max-width: 1240px) {
                            margin-top: px2rem(30px);
                            font-size: 18px;
                        }
                    }
                    .dsc {
                        margin-top: 13px;
                        font-size: 16px;
                        line-height: 30px;
                        color: #666;
                        height: 100%;
                        text-align: center;
                        @media screen and (max-width: 1240px) {
                            margin-top: px2rem(10px);
                            font-size: 15px;
                            line-height: 24px;
                        }
                        p {
                            display: block;
                            @media screen and (max-width: 1240px) {
                                display: none;
                            }
                        }
                        .mb {
                            display: none;
                            @media screen and (max-width: 1240px) {
                                display: block;
                            }
                        }
                    }
                }
                .box-bk:hover {
                    background-color: $baseColor;
                    .icon {
                        color: #fff;
                    }
                    .name {
                        color: #fff;
                    }
                    .dsc {
                        color: #fff;
                    }
                }
            }
        }
        .content-second {
            .first-box {
                width: 33.3%;
                @media screen and (max-width: 1000px) {
                    width: 50%;
                }
            }
            .first-box:nth-last-of-type(1) {
                @media screen and (max-width: 1000px) {
                    width: 100%;
                }
            }
        }
        .values-download {
            display: flex;
            justify-content: space-between;
            margin-top: 33px;
            .left {
                display: flex;
                align-items: center;
                margin-left: 10px;
                .download-img {
                    box-shadow: 4px 5px 8px -2px rgba(0, 0, 0, .4);
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
                .name {
                    font-size: 18px;
                    line-height: 1;
                    color: #333;
                    margin-left: px2rem(10px);
                    @media screen and (max-width: 1000px) {
                        font-size: 16px;
                    }
                }
            }
            .right {
                .btn {
                    display: block;
                    width: 110px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 40px;
                    text-align: center;
                    font-size: 16px;
                    color: #fff;
                    background-color: $onColor;
                    @media screen and (max-width: 1000px) {
                        width: 100px;
                        height: 35px;
                        line-height: 35px;
                        border-radius: 35px;
                        font-size: 15px;
                    }
                }
                .btn:hover {
                    background-color: $baseColor;
                }
            }
        }
    }
}
.about-style {
    padding: px2rem(70px) 0;
    .style-content {
        margin-top: px2rem(40px);
        .content-dsc {
            padding: 0 65px;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 35px;
            color: #888;
            @include articleReset(16px, 35px);
            @media screen and (max-width: 1000px) {
                padding: 0 px2rem(40px);
                font-size: 15px;
                line-height: 24px;
                @include articleReset(15px, 24px);
            }
        }
        .style-container {
            margin-top: px2rem(30px);
            padding-bottom: 30px;
            .slide-box {
                position: relative;
                width: 100%;
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (290 / 385) * 100%;
                    border-radius: px2rem(22px);
                    overflow: hidden;
                    transition: all .3s;
                    img {
                        display: inline-block;
                        @include baseImg;
                        transition: all .3s;
                    }
                }
                .img:before {
                    position: absolute;
                    content: none;
                    top: -2px;
                    left: 0;
                    background-color: #000;
                    opacity: .2;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }
                .dsc {
                    display: none;
                    position: absolute;
                    z-index: 2;
                    bottom: -10px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: (290 / 385) *100%;
                    height: 60px;
                    background-color: $baseColor;
                    color: #fff;
                    font-size: 20px;
                    line-height: 60px;
                    text-align: center;
                    border-radius: 15px;
                    overflow: hidden;
                    transition: all .3s;
                    @media screen and (max-width: 1000px) {
                        height: 40px;
                        font-size: 14px;
                        line-height: 40px;
                        border-radius: 10px;
                        bottom: -5px;
                    }
                    @media screen and (max-width: 500px) {
                        font-size: 12px;
                    }
                }
            }
            .slide-box:hover {
                .img:before {
                    content: '';
                }
                .img {
                    img {
                        transform: scale(1.1);
                    }
                }
                .dsc {
                    display: block;
                }
            }
            .slide-box:nth-of-type(1) {
                margin-bottom: px2rem(20px);
            }
            .cooperation-pagination {
                left: 50%;
                transform: translateX(-50%);
                bottom: 0px;
                .swiper-pagination-bullet {
                    width: 8px;
                    height: 8px;
                    margin: 0 px2rem(10px);
                    background-color: $baseColor;
                    opacity: 1;
                }
                .swiper-pagination-bullet-active {
                    background-color: $onColor;
                }
            }
        }
    }
}
.about-development {
    padding: px2rem(60px) 0 px2rem(100px);
    background: url(../images/values4.png) top left;
    background-repeat: no-repeat;
    .development-content {
        margin-top: px2rem(40px);
        display: flex;
        justify-content: space-between;
        background: url(../images/values7.png) right top;
        background-color: #fff;
        background-repeat: no-repeat;
        padding: 50px 54px 26px;
        box-sizing: border-box;
        border-radius: 20px;
        overflow: hidden;
        @media screen and (max-width: 1240px) {
            padding: 0;
            display: block;
            background: none
        ;
        }
        .content-left {
            width: (500 / 1092) * 100%;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                width: 100%;
                padding: px2rem(60px) px2rem(30px);
                background-color: #fff;
            }
            .name {
                font-size: 26px;
                line-height: 1;
                color: #666;
                @media screen and (max-width: 500px) {
                    font-size: 22px;
                }
            }
            .dsc {
                margin-top: px2rem(22px);
                font-size: 16px;
                line-height: 35px;
                color: #888;
                @media screen and (max-width: 500px) {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
            .dsc-bk {
                position: relative;
                margin-top: px2rem(20px);
                font-size: 16px;
                line-height: 35px;
                color: #fff;
                background-color: $baseColor;
                border-radius: px2rem(15px);
                padding: px2rem(25px) px2rem(30px);
                @media screen and (max-width: 500px) {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
            .dsc-bk:before {
                position: absolute;
                z-index: 9;
                content: '';
                bottom: -12px;
                border-width: 6px 11px 6px 0;
                right: 50%;
                -webkit-transform: translateX(50%);
                -moz-transform: translateX(50%);
                -ms-transform: translateX(50%);
                transform: translateX(50%);
                border-top: 12px solid $baseColor;
                border-left: 12px solid transparent;
                border-right: 12px solid transparent;
            }
            .img {
                margin-top: px2rem(22px);
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        .content-right {
            width: (440 / 1092) * 100%;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                width: 100%;
                background-color: #def3f2;
                padding: px2rem(60px) px2rem(30px);
            }
            .name {
                font-size: 26px;
                line-height: 1;
                color: #666;
                @media screen and (max-width: 500px) {
                    font-size: 22px;
                }
            }
            .dsc {
                margin-top: px2rem(22px);
                font-size: 16px;
                line-height: 35px;
                color: #888;
                @media screen and (max-width: 500px) {
                    font-size: 15px;
                    line-height: 24px;
                }
            }
            .img {
                margin-top: px2rem(30px);
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
}