.channel-container {
    background-color: #f5f5f5;
    padding-top: 135px;
    @media screen and (max-width: 1240px) {
        padding-top: px2rem(100px);
    }
    .channel-content {
        width: 100%;
        background-color: #fff;
        border-radius: 25px;
        overflow: hidden;
        padding-top: 80px;
        padding-bottom: 100px;
        @media screen and (max-width: 1240px) {
            padding-top: 30px;
            padding-bottom: px2rem(100px);
        }
        .channel-title {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
            .tree-title {
                .title-right {
                    .cn {
                        text-align: center;
                        @media screen and (max-width: 500px) {
                            padding-bottom: px2rem(12px);
                        }
                    }
                    .en {
                        text-align: center;
                        @media screen and (max-width: 500px) {
                            padding-top: px2rem(12px);
                        }
                    }
                }
            }
        }
        .channel-title2 {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            span {
                display: block;
            }
            .cn {
                font-size: 36px;
                line-height: 1;
                color: #f5ce82;
                @media screen and (max-width: 1240px) {
                    font-size: 24px;
                }
            }
            .en {
                font-size: 18px;
                line-height: 1;
                margin-left: 16px;
                color: #f5ce82;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    margin-left: 10px;
                }
            }
        }
        .channel-title3 {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 0 20px;
            box-sizing: border-box;
            span {
                display: block;
            }
            .cn {
                font-size: 36px;
                line-height: 1;
                font-weight: bold;
                color: #f5ce82;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 24px;
                }
            }
            .en {
                font-size: 18px;
                line-height: 1;
                margin-top: 16px;
                color: #f5ce82;
                text-transform: uppercase;
                letter-spacing: 2px;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
        }
        .content-info {
            margin-top: px2rem(80px);
            .concept {
                width: 100%;
                background-repeat: no-repeat !important;
                padding: 145px 0 210px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    padding: 80px 0;
                }
                .concept-info {
                    background-repeat: no-repeat !important;
                    max-width: 822px;
                    height: 119px;
                    margin: 45px auto 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 20px;
                    line-height: 26px;
                    color: #62593f;
                    padding: 0 20px;
                    text-align: center;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        height: auto;
                        width: 80%;
                        background: none !important;
                        background-color: #fae19e !important;
                        border-radius: 5px;
                        padding: 40px 20px;
                        margin: px2rem(40px) auto 0;
                        font-size: 16px;
                    }
                    span {
                        display: block;
                    }
                }
            }
            .frameWork {
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .technical {
                padding: 60px 0 120px;
                @media screen and (max-width: 1240px) {
                    padding: 60px 0;
                }
                .technical-content {
                    margin-top: 75px;
                    display: flex;
                    justify-content: center;
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(40px);
                    }
                    .content-box {
                        padding: 70px 45px 20px;
                        box-sizing: border-box;
                        border-right: 2px solid #f5ce82;
                        border-left: 2px solid #f5ce82;
                        border-bottom: 1px solid #f5ce82;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: 1240px) {
                            padding: px2rem(40px) px2rem(30px) px2rem(20px);
                        }
                        .box {
                            width: 100%;
                            .img {
                                text-align: center;
                                img {
                                    max-width: 100%;
                                    height: auto;
                                    @media screen and (max-width: 750px) {
                                        width: auto;
                                        height: 36px;
                                    }
                                }
                            }
                            .n {
                                font-size: 24px;
                                line-height: 1;
                                text-align: center;
                                margin-top: px2rem(30px);
                                color: #f5ce82;
                                @media screen and (max-width: 1240px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    .content-box:nth-last-of-type(1) {
                        border-right: none;
                    }
                    .content-box:nth-of-type(1) {
                        border-left: none;
                    }
                }
            }
            .gzh {
                background-color: #fae19e;
                padding: 175px 220px 130px 180px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    padding: 100px;
                }
                @media screen and (max-width: 750px) {
                    padding: 40px 30px;
                }
                .gzh-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    @media screen and (max-width: 750px) {
                        display: block;
                    }
                    .left {
                        @media screen and (max-width: 750px) {
                            width: 100%;
                        }
                        .info {
                            font-size: 24px;
                            line-height: 36px;
                            font-weight: bold;
                            color: #cc9121;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 22px;
                            }
                            @media screen and (max-width: 750px) {
                                text-align: center;
                            }
                        }
                        .img {
                            margin-top: 66px;
                            @media screen and (max-width: 1240px) {
                                margin-top: 40px;
                            }
                            @media screen and (max-width: 750px) {
                                display: none;
                            }
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .right {
                        margin-left: 80px;
                        @media screen and (max-width: 750px) {
                            width: 100%;
                            margin-left: 0;
                            margin-top: px2rem(60px);
                        }
                        .img {
                            @media screen and (max-width: 750px) {
                                text-align: center;
                            }
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
            .cooperation {
                background-repeat: no-repeat !important;
                padding: 75px 155px 67px;
                @media screen and (max-width: 1240px) {
                    padding: 40px 30px;
                }
                .channel-title3 {
                    .cn {
                        letter-spacing: 2px;
                    }
                    .en {
                        margin-top: 8px;
                        font-size: 12px;
                    }
                }
                .application-content {
                    .btn {
                        button {
                            background: url(../images/channel11.png) center center;
                            background-repeat: no-repeat;
                            font-size: 16px;
                            color: #cc9121;
                            @media screen and (max-width: 1240px) {
                                background: none !important;
                                background-color: #fae19e !important;
                            }
                        }
                    }
                }
            }
        }
    }
}