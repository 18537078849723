.wisdomContainer {
    padding-top: px2rem(88px);
    background-color: #f5f5f5;
    .container-content {
        background: url(../images/educationbk.png) top center;
        background-position: 100% 100%;
        background-repeat: no-repeat;
        background-color: #fff;
        height: auto;
        padding-bottom: px2rem(100px);
        @media screen and (max-width: 1240px) {
            background: none;
            height: auto;
            padding-bottom: 100px;
        }
        .edustion-title {
            p {
                color: #169adb;
                font-size: 30px;
                line-height: 1.1;
                @media screen and (max-width: 1240px) {
                    font-size: 22px;
                    padding: 0 px2rem(30px);
                    line-height: 1.3;
                }
            }
            p:before {
                width: 41px;
                height: 24px;
                left: -61px;
                background: url(../images/education5.png) center center;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 100% 100%;
                @media screen and (max-width: 1240px) {
                    content: none;
                }
            }
            p:after {
                width: 41px;
                height: 24px;
                right: -61px;
                background: url(../images/education6.png) center center;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: 100% 100%;
                @media screen and (max-width: 1240px) {
                    content: none;
                }
            }
        }
        .wisdom-education {
            padding: px2rem(65px) px2rem(30px) 0;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                padding: px2rem(60px) px2rem(30px) 0;
            }
            .we-content {
                margin-top: px2rem(50px);
                display: flex;
                padding: 0 px2rem(60px) px2rem(56px);
                box-sizing: border-box;
                border-bottom: 1px dashed #dedcdc;
                background: url(../images/education3.png) center center;
                background-repeat: no-repeat;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(40px);
                    padding: 0 px2rem(30px) px2rem(60px);
                    display: block;
                }
                .content-left {
                    width: (380 / 1020) * 100%;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .title {
                        display: flex;
                        .btn {
                            height: 54px;
                            font-size: 30px;
                            line-height: 54px;
                            border-radius: 54px;
                            font-weight: bold;
                            background-color: #169adb;
                            text-align: center;
                            padding: 0 px2rem(30px);
                            color: #fff;
                            box-sizing: border-box;
                            @media screen and (max-width: 1240px) {
                                height: 45px;
                                font-size: 20px;
                                line-height: 45px;
                                border-radius: 45px;
                            }
                        }
                    }
                    .dsc {
                        margin-top: px2rem(30px);
                        font-size: 16px;
                        line-height: 30px;
                        padding-left: 14px;
                        color: #505050;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            padding-left: 0;
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                    .button {
                        margin-top: 160px;
                        padding-left: 14px;
                        cursor: pointer;
                        @media screen and (max-width: 1240px) {
                            margin-top: px2rem(60px);
                            padding-left: 0px;
                            display: none;
                        }
                        .img-zb {
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .content-right {
                    flex: 1;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                        margin-top: px2rem(30px);
                    }
                    .img {
                        text-align: right;
                        @media screen and (max-width: 1240px) {
                            text-align: center;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .vs-content {
                display: flex;
                align-items: center;
                margin-top: px2rem(100px);
                padding: 0 px2rem(60px);
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(60px);
                    padding: 0 px2rem(30px);
                    display: block;
                }
                .content-left {
                    width: (456 / 1020) * 100%;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .img {
                        @media screen and (max-width: 1240px) {
                            text-align: center;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
                .content-right {
                    flex: 1;
                    padding-left: 115px;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                        padding-left: 0;
                        margin-top: px2rem(30px);
                    }
                    .dsc {
                        font-size: 16px;
                        line-height: 24px;
                        color: #505050;
                        margin-top: px2rem(10px);
                        text-align: center;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                    .button {
                        margin-top: 90px;
                        @media screen and (max-width: 1240px) {
                            display: none;
                        }
                        .img-zb {
                            text-align: center;
                            img {
                                transform: rotateY(180deg);
                            }
                        }
                    }
                }
            }
            .zc-content {
                margin-top: px2rem(150px);
                display: flex;
                padding: 0 px2rem(50px) 0 px2rem(75px);
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(120px);
                    padding: 0 px2rem(30px);
                    display: block;
                }
                .content-left {
                    width: (380 / 1015) * 100%;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .edustion-title {
                        padding: 0 36px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            padding: 0;
                        }
                    }
                    .dsc {
                        font-size: 16px;
                        line-height: 24px;
                        color: #505050;
                        margin-top: px2rem(10px);
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                    }
                    .button {
                        margin-top: 90px;
                        @media screen and (max-width: 1240px) {
                            display: none;
                        }
                        .img-zb {
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .content-right {
                    flex: 1;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .img {
                        width: 100%;
                        text-align: right;
                        @media screen and (max-width: 1240px) {
                            text-align: center;
                        }
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .lc-content {
                margin-top: px2rem(120px);
                padding: 0 px2rem(90px);
                @media screen and (max-width: 1240px) {
                    padding: 0 px2rem(30px);
                }
                .content-box {
                    margin-top: px2rem(100px);
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(40px);
                    }
                    .img {
                        text-align: center;
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .gt-content {
                margin-top: px2rem(120px);
                padding: 0 px2rem(90px);
                @media screen and (max-width: 1240px) {
                    padding: 0 px2rem(30px);
                }
                .content-box {
                    margin-top: px2rem(100px);
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(40px);
                    }
                    .img {
                        text-align: center;
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
        .characteristics {
            margin-top: px2rem(120px);
            padding: 0 px2rem(100px);
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                padding: 0 px2rem(30px);
            }
            .zs-content {
                margin-top: px2rem(70px);
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(40px);
                }
                .characteristics-title {
                    font-size: 24px;
                    line-height: 30px;
                    font-weight: bold;
                    color: #169adb;
                    @media screen and (max-width: 1240px) {
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
                .characteristics-dsc {
                    font-size: 16px;
                    line-height: 24px;
                    color: #505050;
                    margin-top: px2rem(16px);
                    @media screen and (max-width: 1240px) {
                        font-size: 14px;
                    }
                }
                .img {
                    margin-top: px2rem(42px);
                    text-align: center;
                    @media screen and (max-width: 1240px) {
                        margin-top: px2rem(40px);
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .tj-content {
            margin-top: px2rem(80px);
            padding: px2rem(48px) px2rem(160px) px2rem(44px);
            box-sizing: border-box;
            background-color: #ecf7fd;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(90px);
                padding: px2rem(30px);
            }
            .content-container {
                position: relative;
                margin-top: px2rem(40px);
                padding: 0 px2rem(60px) px2rem(32px);
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    padding: 0 px2rem(30px) px2rem(32px);
                }
                .tj-swiper {
                    .swiper-slide {
                        width: 470px;
                        @media screen and (max-width: 750px) {
                            width: 100%;
                        }
                        .img {
                            position: relative;
                            width: 100%;
                            padding-bottom: (275 / 470) * 100%;
                            img {
                                @include baseImg;
                            }
                        }
                        .dsc {
                            display: none;
                            width: 100%;
                            font-size: 16px;
                            line-height: 20px;
                            color: #fff;
                            background-color: #0781c3;
                            padding: 14px 15px 25px;
                            box-sizing: border-box;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                padding: px2rem(20px);
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                    .swiper-slide-active {
                        .dsc {
                            display: block;
                        }
                    }
                }
                .same {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    color: #79bce0;
                    outline: none;
                    transition: all .3s;
                    cursor: pointer;
                    box-sizing: border-box;
                }
                .same:hover {
                    color: $onColor;
                    border: none;
                }
                .tj-prev {
                    right: 0;
                }
                .tj-next {
                    left: 0;
                    transform: translateY(-50%) rotate(180deg);
                }
                .tj-pagination {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    .swiper-pagination-bullet {
                        margin: 0 2px;
                        background-color: #0781c3;
                    }
                }
            }
        }
        .allRoles {
            padding-top: 100px;
            box-sizing: border-box;
            @media screen and (max-width: 1240px) {
                padding-top: px2rem(90px);
            }
            .allRoles-content {
                margin-top: 90px;
                padding: 0 px2rem(60px);
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(40px);
                    padding: 0 px2rem(30px);
                }
                .img {
                    text-align: center;
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .theSchools {
            margin-top: px2rem(120px);
            .theSchools-content {
                position: relative;
                margin-top: 65px;
                background: url(../images/education15.png) top left;
                background-position: 100% 100%;
                background-repeat: no-repeat;
                padding: 100px 130px 45px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(40px);
                    padding: px2rem(30px);
                }
                .school-container {
                    position: relative;
                    padding-bottom: 110px;
                    @media screen and (max-width: 1240px) {
                        padding-bottom: px2rem(100px);
                    }
                    .school-swiper {
                        .swiper-slide {
                            .box {
                                width: 100%;
                                background-color: #fff;
                                border: 1px solid #ccc;
                                border-radius: 10px;
                                margin-bottom: 50px;
                                box-sizing: border-box;
                                @media screen and (max-width: 1240px) {
                                    margin-bottom: px2rem(20px);
                                    padding: px2rem(20px);
                                }
                                .img {
                                    width: 100%;
                                    position: relative;
                                    padding-bottom: (78 / 285) * 100%;
                                    img {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        max-width: 100%;
                                        max-height: 100%;
                                    }
                                }
                            }
                            .box:nth-last-of-type(1) {
                                margin-bottom: 0;
                            }
                        }
                    }
                    .same {
                        position: absolute;
                        z-index: 2;
                        bottom: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        width: 100px;
                        height: 42px;
                        background-color: #fff;
                        color: #9a9a9a;
                        outline: none;
                        transition: all .3s;
                        cursor: pointer;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            width: 80px;
                            height: 30px;
                        }
                    }
                    .same:hover {
                        color: $onColor;
                        border: none;
                    }
                    .Schools-prev {
                        left: 50%;
                        opacity: .6;
                    }
                    .Schools-next {
                        right: 50%;
                        transform: rotate(180deg);
                    }
                }
            }
        }
        .cooperationCases {
            margin-top: px2rem(80px);
            padding: 0 75px;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(90px);
                padding: 0 px2rem(30px);
            }
            .achievement-top {
                position: relative;
                margin-top: px2rem(68px);
                display: flex;
                @media screen and (max-width: 1240px) {
                    display: block;
                }
                .left {
                    width: (522 / 1050) * 100%;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (365 / 522) * 100%;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                }
                .right {
                    flex: 1;
                    position: relative;
                    margin-left: -30px;
                    background: url(../images/education18.png) top left;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding: 50px 48px 30px 44px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    overflow: hidden;
                    @media screen and (max-width: 1240px) {
                        margin-left: 0;
                        margin-top: -20px;
                        padding: px2rem(30px);
                    }
                    .right-content {
                        width: 100%;
                        .title {
                            font-size: 24px;
                            line-height: 1;
                            color: #fff;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .dsc {
                            margin-top: px2rem(22px);
                            font-size: 16px;
                            line-height: 22px;
                            color: #fff;
                            @include articleReset(16px, 60px);
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                                @include articleReset(13px, 20px);
                            }
                        }
                    }
                }
            }
            .achievement-top:hover {
                .left {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .achievement-bottom {
                position: relative;
                margin-top: px2rem(38px);
                .box {
                    width: 100%;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (450 / 487) * 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .dsc-box {
                        position: relative;
                        z-index: 1;
                        margin-top: -45px;
                        background-color: #0781c3;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        padding: 40px 45px 27px 50px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            margin-top: -20px;
                            padding: px2rem(30px);
                            height: auto;
                        }
                        .title {
                            font-size: 24px;
                            line-height: 1;
                            color: #fff;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .dsc {
                            font-size: 16px;
                            line-height: 22px;
                            height: 88px;
                            overflow: hidden;
                            color: #fff;
                            margin-top: 20px;
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                                height: 72px;
                                margin-top: px2rem(15px);
                            }
                        }
                    }
                }
                .box:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .dsc-box {
                        .title {
                            transform: translateX(10px);
                        }
                    }
                }
                .same {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                    background-color: #e0dfdf;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    color: #727272;
                    outline: none;
                    transition: all .3s;
                    cursor: pointer;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        width: 40px;
                        height: 40px;
                        font-size: 16px;
                    }
                    @media screen and (max-width: 500px) {
                        width: 30px;
                        height: 30px;
                        font-size: 12px;
                    }
                }
                .same:hover {
                    background-color: #0781c3;
                    color: #fff;
                    border: none;
                }
                .achievement-next {
                    right: -38px;
                    @media screen and (max-width: 500px) {
                        right: 0px;
                    }
                }
                .achievement-prev {
                    left: -38px;
                    transform: translateY(-50%) rotate(180deg);
                    @media screen and (max-width: 500px) {
                        left: 0px;
                    }
                }
            }
        }
    }
}