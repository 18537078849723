.index-banner {
    position: relative;
    .swiper-container {
        .swiper-slide {
            .img {
                width: 100%;
                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    padding-bottom: (820 / 1920) * 100%;
                    @media screen and (max-width: 1000px) {
                        padding-bottom: (450 / 750) * 100%;
                    }
                }
                img {
                    @include baseImg;
                }
                video {
                    @include baseImg;
                }
            }
        }
        .swiper-button-next1 {
            right: px2rem(100px);
            @media screen and (max-width: 1000px) {
                right: px2rem(30px);
            }
        }
        .swiper-button-prev1 {
            left: px2rem(100px);
            @media screen and (max-width: 1000px) {
                left: px2rem(30px);
            }
        }
        .swiper-button-next1,
        .swiper-button-prev1 {
            outline: none;
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            z-index: 10;
            cursor: pointer;
        }
        .swiper-button-disabled {
            opacity: 1;
            cursor: auto;
            //pointer-events: none
        }
        .swiper-button-prev1:before,
        .swiper-button-next1:before {
            position: absolute;
            font-size: 34px;
            color: #fff;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 1000px) {
                font-size: px2rem(26px);
            }
        }
        .swiper-button-prev1:before {
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        .swiper-button-next1:hover:before,
        .swiper-button-prev1:hover:before {
            opacity: .8;
        }
        .index-pagination {
            outline: none;
            bottom: px2rem(20px);
            @media screen and (max-width: 500px) {
                bottom: 5px;
            }
            .swiper-pagination-bullet {
                background-color: #fff;
                margin: 0 px2rem(10px);
                opacity: 1;
                width: 10px;
                height: 10px;
                @media screen and (max-width: 500px) {
                    width: 8px;
                    height: 8px;
                }
            }
            .swiper-pagination-bullet-active {
                background-color: $baseColor;
            }
        }
    }
    .guide {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        animation: updown 1.5s infinite;
        cursor: pointer;
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.pc-banner {
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
}
.mb-banner {
    display: none;
    padding-top: 100px;
    @media screen and (max-width: 1000px) {
        display: block;
        padding-top: 100px;
    }
    @media screen and (max-width: 500px) {
        padding-top: 60px;
    }
    .swiper-pagination {
        bottom: 38px;
        .swiper-pagination-bullet {
            width: 14px;
            height: 14px;
            margin: 0 9px;
            background-color: #fff;
            opacity: 1;
            @media screen and (max-width: 500px) {
                width: 8px;
                height: 8px;
                margin: 0 4px;
            }
        }
        .swiper-pagination-bullet-active {
            background-color: $baseColor;
        }
    }
}
.title-same {
    position: relative;
    display: flex;
    margin-right: 145px;
    box-sizing: border-box;
    @media screen and (max-width: 1600px) {
        margin-right: 0px;
    }
    .same-left {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .cn {
            font-size: 45px;
            line-height: 42px;
            font-family: 微软雅黑;
            color: #4c4c4c;
            padding-bottom: px2rem(9px);
            box-sizing: border-box;
            text-align: right;
            @media screen and (max-width: 1600px) {
                font-size: 32px;
                line-height: 32px;
            }
            @media screen and (max-width: 500px) {
                font-size: 22px;
                line-height: 22px;
            }
        }
        .en {
            padding-top: px2rem(9px);
            font-size: 14px;
            line-height: 14px;
            color: #999;
            font-family: Arial;
            text-align: right;
            text-transform: uppercase;
            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    .same-right {
        margin-left: 20px;
        @media screen and (max-width: 1600px) {
            margin-left: 10px;
        }
        .img {
            img {
                max-width: 100%;
                height: auto;
                @media screen and (max-width: 500px) {
                    max-width: none;
                    width: 60px;
                }
            }
        }
    }
    .lunbo-btn {
        order: 3;
        display: flex;
        align-items: center;
        .same-prev {
            margin-right: px2rem(20px);
        }
        .same-prev,
        .same-next {
            margin-top: px2rem(20px);
            position: relative;
            outline: none;
            box-sizing: border-box;
            z-index: 10;
            cursor: pointer;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 1px solid #cdcdcd;
            background-color: #fff;
            @media screen and (max-width: 1600px) {
                width: 40px;
                height: 40px;
            }
            @media screen and (max-width: 1000px) {
                width: px2rem(80px);
                height: px2rem(80px);
            }
        }
        .same-prev:before,
        .same-next:before {
            position: absolute;
            font-size: 14px;
            color: #333;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }
        .same-prev:before {
            transform: translate(-50%, -50%) rotateY(180deg);
        }
        .same-next:hover,
        .same-prev:hover {
            background-color: $baseColor;
            border: none;
        }
        .same-next:hover:before,
        .same-prev:hover:before {
            color: #fff;
        }
        .swiper-button-disabled {
            opacity: 1;
            cursor: auto;
            //pointer-events: none
        }
    }
}
.title-same:before {
    position: absolute;
    left: 0;
    top: 66%;
    content: '';
    width: calc(100% - 79px);
    height: 1px;
    background-color: #e5e5e5;
    @media screen and (max-width: 1600px) {
        top: 60%;
    }
    @media screen and (max-width: 500px) {
        width: calc(100% - 54px);
        top: 57%;
    }
}
.title-same-ord {
    margin-right: 0;
    .same-left {
        order: 2;
        .cn,
        .en {
            text-align: left;
        }
    }
    .same-right {
        order: 1;
        margin-left: 0;
        margin-right: 20px;
        @media screen and (max-width: 1600px) {
            margin-right: 10px;
        }
    }
}
.title-same-ord:before {
    right: 0;
    left: auto;
}
.ckgd {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: px2rem(20px);
    @media screen and (max-width: 1600px) {
        margin-top: 15px;
    }
    a {
        display: inline-block;
        width: 150px;
        height: 40px;
        line-height: 40px;
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        background-color: $baseColor;
        transition: all .3s;
        @media screen and (max-width: 1600px) {
            width: 100px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            font-size: 14px;
        }
    }
    a:hover {
        background-color: $onColor;
    }
}
.jjfa-container {
    box-sizing: border-box;
    display: flex;
    background-color: #fff;
    padding: 110px 15px 100px 160px;
    @media screen and (max-width: 1640px) {
        padding: 110px 10px 100px px2rem(52px);
    }
    @media screen and (max-width: 1000px) {
        display: none;
        padding: 110px 10px 100px px2rem(30px);
    }
    .jjfa-left {
        border-radius: 15px;
        width: (440 / 1745) * 100%;
        overflow: hidden;
        .left-nav {
            padding: 128px 0 45px;
            box-sizing: border-box;
            width: 100%;
            background: url(../images/jjfabk_03.png) center center;
            background-size: cover;
            background-repeat: no-repeat;
            @media screen and (max-width: 1600px) {
                padding: 80px 0 20px;
            }
            .nav-list {
                padding: 38px 40px;
                box-sizing: border-box;
                cursor: pointer;
                border-bottom: 1px dashed #ccc;
                @media screen and (max-width: 1600px) {
                    padding: 30px;
                }
                @media screen and (max-width: 1200px) {
                    padding: 20px;
                }
                .list-cn {
                    font-size: 20px;
                    line-height: 20px;
                    color: #000;
                    @media screen and (max-width: 1600px) {
                        font-size: 16px;
                        line-height: 16px;
                    }
                }
                .list-en {
                    margin-top: 10px;
                    font-size: 14px;
                    line-height: 14px;
                    font-family: Arial;
                    color: #919191;
                    text-transform: uppercase;
                    @media screen and (max-width: 1600px) {
                        margin-top: 5px;
                    }
                }
            }
            .nav-list:nth-last-of-type(1) {
                border-bottom: none;
            }
            .on {
                background-color: $baseColor;
                .list-cn {
                    color: #fff;
                }
                .list-en {
                    color: #a8d6d4;
                }
            }
        }
    }
    .jjfa-right {
        flex: 1;
        width: (1305 / 1745) * 100%;
        .lunbo {
            position: relative;
            margin-top: 38px;
            margin-left: -120px;
            padding-bottom: 85px;
            box-sizing: border-box;
            @media screen and (max-width: 1600px) {
                margin-left: -20px;
            }
            .swiper-slide {
                width: (780 / 1410) * 100%;
                overflow: hidden;
                //@media screen and (max-width: 1600px) {
                //    width: (1000 / 1430) * 100%;
                //}
                .lunbo-box {
                    position: relative;
                    width: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                    .img {
                        position: relative;
                        padding-bottom: (585 / 780) * 100%;
                        background-repeat: no-repeat !important;
                        background-size: 100% 100% !important;
                        transition: all .3s;
                    }
                    .dsc {
                        position: absolute;
                        left: 0;
                        bottom: 0;
                        width: 100%;
                        background-color: rgba($baseColor, .9);
                        border-radius: 15px;
                        padding: 24px 40px 25px 50px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1600px) {
                            padding: 15px;
                        }
                        .title {
                            font-size: 40px;
                            line-height: 40px;
                            color: #fff;
                            opacity: 1;
                            @media screen and (max-width: 1600px) {
                                font-size: 32px;
                                line-height: 30px;
                            }
                        }
                        .t-d {
                            font-weight: bold;
                            font-size: 20px;
                            line-height: 20px;
                            color: rgba(255, 255, 255, .8);
                            margin-top: 15px;
                            opacity: 1;
                            @media screen and (max-width: 1600px) {
                                font-size: 16px;
                                line-height: 16px;
                                margin-top: 10px;
                            }
                        }
                        .xq-box {
                            display: none;
                            padding-top: 23px;
                            padding-bottom: 27px;
                            box-sizing: border-box;
                            @media screen and (max-width: 1600px) {
                                padding: 10px 0;
                            }
                            .xq-d {
                                font-size: 16px;
                                line-height: 30px;
                                color: #fff;
                                opacity: .8;
                                @media screen and (max-width: 1600px) {
                                    font-size: 14px;
                                    line-height: 20px;
                                }
                            }
                            .xq-btn {
                                margin-top: 33px;
                                box-sizing: border-box;
                                opacity: 1;
                                @media screen and (max-width: 1600px) {
                                    margin-top: 15px;
                                }
                                a {
                                    display: inline-block;
                                    width: 150px;
                                    height: 40px;
                                    font-size: 16px;
                                    line-height: 40px;
                                    text-align: center;
                                    border-radius: 40px;
                                    color: #fff;
                                    background-color: $onColor;
                                    @media screen and (max-width: 1600px) {
                                        width: 100px;
                                        height: 30px;
                                        font-size: 14px;
                                        line-height: 30px;
                                        border-radius: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .swiper-slide:hover {
                .lunbo-box {
                    .img {
                        transform: scale(1.1);
                    }
                    .dsc {
                        .xq-box {
                            display: block;
                        }
                    }
                }
            }
            .swiper-button-next1 {
                right: px2rem(145px);
                @media screen and (max-width: 1600px) {
                    right: 0;
                }
            }
            .swiper-button-prev1 {
                right: px2rem(225px);
                @media screen and (max-width: 1600px) {
                    right: px2rem(60px);
                }
            }
            .swiper-button-next1,
            .swiper-button-prev1 {
                outline: none;
                box-sizing: border-box;
                position: absolute;
                bottom: 0px;
                z-index: 10;
                cursor: pointer;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                border: 1px solid #cdcdcd;
                @media screen and (max-width: 1600px) {
                    width: 40px;
                    height: 40px;
                }
            }
            .swiper-button-disabled {
                opacity: 1;
                cursor: auto;
                //pointer-events: none
            }
            .swiper-button-prev1:before,
            .swiper-button-next1:before {
                position: absolute;
                font-size: 16px;
                color: #333;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @media screen and (max-width: 1600px) {
                    font-size: 14px;
                }
            }
            .swiper-button-prev1:before {
                transform: translate(-50%, -50%) rotateY(180deg);
            }
            .swiper-button-next1:hover,
            .swiper-button-prev1:hover {
                background-color: $baseColor;
                border: none;
            }
            .swiper-button-next1:hover:before,
            .swiper-button-prev1:hover:before {
                color: #fff;
            }
        }
    }
}
.jjfa-container-mb {
    display: none;
    padding: px2rem(100px) 0;
    @media screen and (max-width: 1000px) {
        display: block;
    }
    .lunbo-btn {
        background-color: #fff;
        position: relative;
    }
    .title-same {
        .same-left {
            order: 2;
        }
        .same-right {
            order: 3;
        }
        .lunbo-btn {
            order: 1;
        }
    }
    .jjfa-nav {
        margin-top: px2rem(30px);
        margin-left: px2rem(30px);
        box-sizing: border-box;
        overflow: hidden;
        .inside-swiper {
            width: 100%;
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            .swiper-slide {
                position: relative;
                display: inline-block;
                width: auto;
                overflow: hidden;
            }
            .swiper-slide:nth-last-of-type(1) {
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
            }
            .on {
                a {
                    background-color: $baseColor;
                    color: #fff;
                }
            }
            a {
                display: block;
                font-size: px2rem(32px);
                height: px2rem(100px);
                width: px2rem(290px);
                line-height: px2rem(100px);
                color: #000;
                background-color: #e5e5e5;
                text-align: center;
                box-sizing: border-box;
                @media screen and (max-width: 500px) {
                    font-size: 16px;
                }
            }
        }
    }
    .jjfa-mb-container {
        margin-top: px2rem(30px);
        .lunbo-box {
            display: block;
            position: relative;
            width: 100%;
            border-radius: 15px;
            overflow: hidden;
            .img {
                position: relative;
                padding-bottom: (585 / 780) * 100%;
                background-repeat: no-repeat !important;
                background-size: 100% 100% !important;
                .dsc {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    background-color: rgba($baseColor, .95);
                    border-radius: 15px;
                    padding: px2rem(24px) px2rem(40px) px2rem(26px);
                    box-sizing: border-box;
                    .title {
                        font-size: px2rem(40px);
                        line-height: px2rem(38px);
                        color: #fff;
                        opacity: 1;
                    }
                    .t-d {
                        font-size: px2rem(24px);
                        line-height: px2rem(22px);
                        color: #fff;
                        margin-top: 10px;
                        opacity: 1;
                        @media screen and (max-width: 1000px) {
                            font-size: 13px;
                            line-height: 13px;
                        }
                    }
                }
            }
        }
    }
}
.ywtx-container {
    display: block;
    background-color: #f5f5f5;
    padding: 100px 0;
    box-sizing: border-box;
    @media screen and (max-width: 1000px) {
        display: none;
    }
    .title-same {
        padding-right: 0px;
        .same-left {
            order: 2;
            .cn,
            .en {
                text-align: left;
            }
        }
        .same-right {
            order: 1;
            margin-left: 0;
            margin-right: 20px;
            @media screen and (max-width: 1600px) {
                margin-right: 10px;
            }
        }
        .ckgd {
            order: 3;
        }
    }
    .ywtx-content {
        margin-top: 40px;
        display: flex;
        border-radius: 15px;
        box-sizing: border-box;
        overflow: hidden;
        .content-box {
            width: (320 / 1600) * 100%;
            transition: all .5s;
            cursor: pointer;
            .img-bk {
                display: block;
                position: relative;
                height: 690px;
                background-repeat: no-repeat !important;
                background-position: center center !important;
                transition: all .5s;
                @media screen and (max-width: 1600px) {
                    height: 500px;
                }
                .title {
                    position: absolute;
                    top: (100 / 690) * 100%;
                    left: 70px;
                    @media screen and (max-width: 1600px) {
                        left: 30px;
                    }
                    .b {
                        font-size: 32px;
                        line-height: 32px;
                        color: #fff;
                        @media screen and (max-width: 1600px) {
                            font-size: 22px;
                            line-height: 22px;
                        }
                    }
                    .s {
                        font-size: 16px;
                        line-height: 16px;
                        color: #fff;
                        margin-top: 15px;
                        @media screen and (max-width: 1600px) {
                            font-size: 14px;
                            line-height: 14px;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .on {
            width: (640 / 1600) * 100%;
        }
    }
}
.ywtx-container-mb {
    display: none;
    background-color: #f5f5f5;
    padding: px2rem(100px) 0;
    @media screen and (max-width: 1000px) {
        display: block;
    }
    .lunbo-btn {
        position: relative;
        background-color: #f5f5f5;
    }
    .title-same {
        .same-left {
            order: 2;
            .left-box {
                .cn,
                .en {
                    text-align: left;
                }
            }
        }
        .same-right {
            order: 1;
            margin-left: 0px;
            margin-right: px2rem(20px);
        }
        .lunbo-btn {
            order: 3;
        }
    }
    .title-same:before {
        left: auto;
        right: 0;
    }
    .ywtx-content-mb {
        margin-top: px2rem(30px);
        border-radius: 15px;
        overflow: hidden;
    }
    .swiper-slide {
        display: block;
        width: 100%;
        position: relative;
        .img {
            position: relative;
            padding-bottom: (690 / 640) * 100%;
            overflow: hidden;
            img {
                @include baseImg;
            }
        }
        .dsc {
            position: absolute;
            top: (85 / 690) * 100%;
            left: (60 / 640) * 100%;
            .b {
                font-size: px2rem(45px);
                line-height: px2rem(45px);
                color: #fff;
            }
            .s {
                margin-top: px2rem(12px);
                font-size: px2rem(28px);
                line-height: px2rem(28px);
                color: #fff;
            }
        }
    }
    .ckgd {
        margin-top: px2rem(50px);
        a {
            display: block;
            width: 100%;
            height: px2rem(80px);
            line-height: px2rem(80px);
            font-size: 20px;
            color: #fff;
            background-color: $baseColor;
            @media screen and (max-width: 500px) {
                font-size: 16px;
            }
        }
    }
}
.about-container {
    padding: px2rem(100px) 0;
    background-color: #fff;
    .gyqc-content {
        display: flex;
        .left {
            width: (620 / 1600) * 100%;
            @media screen and (max-width: 1000px) {
                width: 100%;
            }
            .title-same {
                @media screen and (max-width: 1000px) {
                    margin-right: px2rem(-30px);
                }
            }
            .img-mb {
                display: none;
                margin-top: px2rem(30px);
                width: 100%;
                border-radius: 15px;
                overflow: hidden;
                box-sizing: border-box;
                @media screen and (max-width: 1000px) {
                    display: block;
                }
                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (490 / 980) * 100%;
                    img {
                        @include baseImg;
                        transition: all .3s;
                    }
                    video {
                        @include baseImg;
                        object-fit: cover;
                        outline: none;
                        cursor: pointer;
                    }
                }
            }
            .dsc {
                margin-top: px2rem(40px);
                font-size: 16px;
                line-height: 35px;
                color: #666;
                margin-right: 78px;
                box-sizing: border-box;
                @media screen and (max-width: 1600px) {
                    font-size: 14px;
                    line-height: 22px;
                    margin-right: 30px;
                }
                @media screen and (max-width: 1000px) {
                    font-size: px2rem(28px);
                    line-height: px2rem(50px);
                    margin-right: 0px;
                }
            }
            .ckgd {
                margin-top: 60px;
                @media screen and (max-width: 1600px) {
                    margin-top: 20px;
                }
                @media screen and (max-width: 1000px) {
                    margin-top: px2rem(38px);
                }
            }
        }
        .right {
            width: (980 / 1600) * 100%;
            border-radius: 15px;
            overflow: hidden;
            @media screen and (max-width: 1000px) {
                display: none;
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (490 / 980) * 100%;
                overflow: hidden;
                cursor: pointer;
                img {
                    @include baseImg;
                    transition: all .3s;
                }
                video {
                    @include baseImg;
                    object-fit: cover;
                    outline: none;
                    cursor: pointer;
                }
            }
            .img:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    .gyqc-content:nth-last-of-type(1) {
        margin-top: px2rem(110px);
        @media screen and (max-width: 1000px) {
            margin-top: px2rem(100px);
        }
        .left {
            order: 2;
            .title-same {
                margin-right: 0;
                @media screen and (max-width: 1000px) {
                    margin-left: px2rem(-30px);
                }
            }
            .dsc {
                max-width: none;
                width: auto;
                margin-left: 78px;
                margin-right: 0px;
                @media screen and (max-width: 1000px) {
                    margin-left: 0px;
                }
            }
            .ckgd {
                margin-left: 78px;
                @media screen and (max-width: 1000px) {
                    margin-left: 0px;
                }
            }
        }
        .right {
            order: 1;
        }
    }
}
.qldt-container {
    background-color: #e1f3f3;
    padding: px2rem(100px) 0;
    .lunbo-btn {
        position: relative;
        background-color: #e1f3f3;
    }
    .qldt-content {
        width: 100%;
        margin-top: 40px;
        box-sizing: border-box;
        @media screen and (max-width: 1000px) {
            margin-top: px2rem(30px);
        }
        .swiper-wrapper {
            .content-box {
                position: relative;
                width: 100%;
                .img {
                    width: 100%;
                    position: relative;
                    padding-bottom: (277 / 370) * 100%;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    overflow: hidden;
                    img {
                        @include baseImg;
                    }
                }
                .dsc {
                    display: block;
                    position: relative;
                    z-index: 2;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 30px 28px 27px;
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    overflow: hidden;
                    background-color: #fff;
                    @media screen and (max-width: 1600px) {
                        padding: 20px;
                    }
                    @media screen and (max-width: 1000px) {
                        padding: px2rem(26px) px2rem(26px) px2rem(30px);
                    }
                    .title {
                        font-size: 18px;
                        line-height: 30px;
                        color: #333;
                        height: 60px;
                        overflow: hidden;
                        @media screen and (max-width: 1600px) {
                            font-size: 16px;
                            line-height: 24px;
                            height: 48px;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: px2rem(26px);
                            line-height: px2rem(35px);
                            height: px2rem(70px);
                        }
                    }
                    .time {
                        font-size: 14px;
                        line-height: 30px;
                        font-family: Arial;
                        color: #999;
                        @media screen and (max-width: 1600px) {
                            font-size: 12px;
                            line-height: 24px;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: px2rem(22px);
                            line-height: px2rem(30px);
                        }
                    }
                    .btn {
                        display: none;
                        margin-top: 20px;
                        margin-bottom: 19px;
                        @media screen and (max-width: 1600px) {
                            margin: 10px 0;
                        }
                        .btn-box {
                            display: inline-block;
                            width: 150px;
                            height: 40px;
                            font-size: 16px;
                            line-height: 40px;
                            border-radius: 40px;
                            text-align: center;
                            color: #fff;
                            background-color: $onColor;
                            @media screen and (max-width: 1600px) {
                                width: 100px;
                                height: 30px;
                                font-size: 14px;
                                line-height: 30px;
                                border-radius: 30px;
                            }
                        }
                    }
                }
                .dsc-hover {
                    position: absolute;
                    bottom: 0px;
                    height: auto;
                    display: none;
                    margin-top: -80px;
                    left: 50%;
                    transform: translateX(-50%);
                    background: url(../images/qldtbk_03.png) right top;
                    background-repeat: no-repeat !important;
                    //background-size: cover !important;
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                    @media screen and (max-width: 1600px) {
                        margin-top: -40px;
                    }
                    @media screen and (max-width: 1000px) {
                        display: none !important;
                    }
                    .title {
                        color: #fff;
                    }
                    .time {
                        color: #a8d6d4;
                    }
                    .btn {
                        display: block;
                    }
                }
            }
        }
    }
}
@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}

@keyframes updown {
    0% {
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%)
    }
    50% {
        -webkit-transform: translate(-50%, 20%);
        -moz-transform: translate(-50%, 20%);
        transform: translate(-50%, 20%)
    }
    100% {
        -webkit-transform: translate(-50%, 0%);
        -moz-transform: translate(-50%, 0%);
        transform: translate(-50%, 0%)
    }
}