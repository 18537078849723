.inside-menu {
    position: relative;
    margin-top: -1px;
    z-index: 2;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    .wp1300 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .inside-swiper {
        overflow: hidden;
        .swiper-wrapper {
            font-size: 0;
        }
        .swiper-slide {
            position: relative;
            display: inline-block;
            vertical-align: top;
            width: auto;
            padding: px2rem(15px) 0;
            a {
                text-align: center;
                box-sizing: border-box;
                height: 40px;
                line-height: 40px;
                min-width: 120px;
                padding: 0 px2rem(10px);
                border-radius: 40px;
                font-size: px2rem(16px);
                position: relative;
                display: block;
                color: #333;
                transition: all .3s;
                @media screen and (max-width: 700px) {
                    min-width: 100px;
                    height: 35px;
                    line-height: 35px;
                    font-size: 14px;
                }
            }
            &.on,
            &:hover {
                a {
                    color: #fff;
                    background-color: $baseColor;
                    //font-weight: bold;
                }
            }
        }
    }
}
//弹窗页面的css
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
}
.video-popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9001;
    &.show {
        .video-popup-box,
        .video-popup-mask {
            opacity: 1;
        }
        .video-popup-box {
            transform: translate(-50%, -50%) scale(1);
        }
    }
    &-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 1340px;
        background-color: #000;
        box-shadow: 0 6px 20px rgba(0, 0, 0, .8);
        opacity: 0;
        z-index: 6;
        transform: translate(-50%, -50%) scale(.8);
        transition: all .4s;
    }
    .video {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: (638 / 1340) * 100%;
        overflow: hidden;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
            overflow: hidden;
        }
    }
    &-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .9);
        z-index: 2;
        opacity: 0;
        transition: all .4s;
    }
    &-close {
        position: absolute;
        top: 20px;
        right: 20px;
        color: $baseColor;
        font-size: 24px;
        cursor: pointer;
        transition: all .3s;
        z-index: 9;
        &:hover {
            transform: rotate(180deg);
        }
    }
    @media screen and (max-width: 1440px) {
        &-box {
            width: calc(100% - 100px);
        }
    }
    @media screen and (max-width: 750px) {
        &.show {
            .video-popup-box {
                transform: scale(1);
            }
        }
        &-box {
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            transform: scale(.8);
        }
        .video {
            padding-bottom: 60%;
        }
        &-close {
            top: px2rem(40px);
            right: px2rem(40px);
            font-size: px2rem(40px);
        }
    }
}

