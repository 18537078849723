.good-container {
    background-color: #f5f5f5;
    padding-top: 135px;
    @media screen and (max-width: 1240px) {
        padding-top: px2rem(100px);
    }
    .good-content {
        width: 100%;
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        padding-top: 45px;
        padding-bottom: 170px;
        @media screen and (max-width: 1240px) {
            padding-top: 30px;
            padding-bottom: px2rem(100px);
        }
        .good-title {
            width: 100%;
            padding: 0 10px;
            box-sizing: border-box;
        }
        .tree-title {
            .title-right {
                .cn {
                    text-align: center;
                    @media screen and (max-width: 500px) {
                        padding-bottom: px2rem(12px);
                    }
                }
                .en {
                    text-align: center;
                    @media screen and (max-width: 500px) {
                        padding-top: px2rem(12px);
                    }
                }
            }
        }
        .content-info {
            margin-top: px2rem(60px);
            .wd3_7 {
                display: flex;
                @media screen and (max-width: 750px) {
                    display: block;
                    margin-bottom: px2rem(20px);
                }
                .wd3 {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #3aa9e1;
                    padding: 30px;
                    box-sizing: border-box;
                    span {
                        display: block;
                        font-size: 45px;
                        line-height: 72px;
                        color: #fff;
                        text-align: center;
                        @media screen and (max-width: 1240px) {
                            font-size: 30px;
                            line-height: 50px;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: 30px;
                            line-height: 40px;
                        }
                        @media screen and (max-width: 1000px) {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                }
                .wd7 {
                    width: (785 / 1200) * 100%;
                    @media screen and (max-width: 750px) {
                        width: 100%;
                    }
                    .img {
                        width: 100%;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
            }
            .wd50 {
                display: flex;
                @media screen and (max-width: 750px) {
                    display: block;
                    margin-bottom: px2rem(20px);
                }
                .img-box {
                    width: 50%;
                    @media screen and (max-width: 750px) {
                        width: 100%;
                    }
                    .img {
                        width: 100%;
                        img {
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .info-box {
                    width: 50%;
                    background-repeat: no-repeat !important;
                    background-color: #1ca7a2 !important;
                    padding: 60px 40px 0 ;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        padding: 30px 20px 0 ;
                    }
                    @media screen and (max-width: 750px) {
                        width: 100%;
                        padding: 50px 20px;
                    }
                    .dsc {
                        width: 100%;
                        .b {
                            font-size: 27px;
                            line-height: 1;
                            color: #fff;
                            margin-bottom: px2rem(18px);
                            @media screen and (max-width: 1240px) {
                                font-size: 20px;
                            }
                        }
                        .s {
                            font-size: 16px;
                            line-height: 1.5;
                            color: #fff;
                            @media screen and (max-width: 1240px) {
                                font-size: 14px;
                                line-height: 1.3;
                            }
                        }
                    }
                }
                .yellow {
                    background-color: #f8b739 !important;
                }
                .blue {
                    background-color: #3a71b6 !important;
                }
                .green {
                    background-color: #99c524 !important;
                }
            }
            .wd100 {
                display: flex;
                align-items: center;
                background-repeat: no-repeat !important;
                height: 331px;
                justify-content: center;
                @media screen and (max-width: 1240px) {
                    height: 250px;
                }
                @media screen and (max-width: 750px) {
                    height: auto;
                    padding: 40px 20px;
                }
                .info-box {
                    .cn {
                        font-size: 38px;
                        color: #fff;
                        @media screen and (max-width: 1240px) {
                            font-size: 30px;
                            text-align: center;
                        }
                    }
                    .en {
                        font-size: 15px;
                        color: #1ca7a2;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}