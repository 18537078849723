.musi-container {
    .tree-title {
        .title-left {
            margin-right: 0;
        }
        .title-right {
            .left-box {
                .cn {
                    @media screen and (max-width: 500px) {
                        padding-bottom: px2rem(12px);
                    }
                }
                .en {
                    @media screen and (max-width: 500px) {
                        padding-top: px2rem(12px);
                    }
                }
            }
        }
        .title-btn {
            background-color: $baseColor;
        }
    }
    .tree-title:before {
        width: calc(100% - 68px);
    }
    .kt-content {
        padding: 62px 0 84px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .kt-info {
            margin-top: 50px;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(40px);
            }
            .kt-title {
                font-size: 24px;
                line-height: 54px;
                font-weight: bold;
                color: #888;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 20px;
                    line-height: 1.3;
                }
            }
            .kt-dsc {
                font-size: 16px;
                line-height: 35px;
                color: #888;
                max-width: 820px;
                margin: 13px auto 0;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
            .kt-img {
                margin-top: px2rem(40px);
                text-align: center;
                width: 100%;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .ms-content {
        background-color: #f5f5f5;
        padding: 68px 0 105px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .ms-info {
            width: 100%;
            margin-top: px2rem(28px);
            .ms-dsc {
                font-size: 16px;
                line-height: 35px;
                color: #888;
                max-width: 820px;
                margin: 0 auto;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
            .ms-list {
                margin-top: px2rem(66px);
                display: flex;
                flex-wrap: wrap;
                .content-box {
                    position: relative;
                    width: 33.3%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #fff;
                    @media screen and (max-width: 750px) {
                        width: 50%;
                        margin-bottom: px2rem(30px);
                    }
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (399 / 403) * 100%;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .img:hover {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .box {
                        padding: px2rem(20px);
                        box-sizing: border-box;
                        .title {
                            position: relative;
                            font-size: 23px;
                            line-height: 30px;
                            color: $baseColor;
                            text-align: center;
                            padding-bottom: px2rem(20px);
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 1.3;
                            }
                        }
                        .title:before {
                            position: absolute;
                            content: '';
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            width: 30px;
                            height: 2px;
                            background-color: #ededed;
                            @media screen and (max-width: 1240px) {
                                width: 20px;
                            }
                        }
                        .dsc {
                            margin-top: px2rem(18px);
                            font-size: 16px;
                            line-height: 30px;
                            color: #002c2a;
                            text-align: center;
                            @media screen and (max-width: 1240px) {
                                font-size: 14px;
                                line-height: 1.3;
                            }
                        }
                    }
                }
                .content-box:nth-of-type(2n+2):before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    top: 50%;
                    transform: translateY(-50%);
                    right: -30px;
                    border-width: 25px 0 25px 32px;
                    border-style: solid;
                    border-color: transparent transparent transparent #fff;
                    @media screen and (max-width: 750px) {
                        content: none;
                    }
                }
                .content-box:nth-of-type(2):before {
                    right: auto;
                    left: -30px;
                    transform: translateY(-50%) rotate(180deg);
                }
                .content-box:nth-of-type(3) {
                    @media screen and (max-width: 750px) {
                        order: 5;
                    }
                }
                .content-box:nth-of-type(6) {
                    @media screen and (max-width: 750px) {
                        order: 6;
                    }
                }
                .content-box:nth-of-type(6):before {
                    top: -30px;
                    right: 50%;
                    transform: translateX(50%) rotate(-90deg);

                }
            }
        }
    }
    .ts-content {
        background-color: #1ca7a2;
        padding: 86px 0 60px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .tree-title {
            .title-right {
                .left-box {
                    .cn {
                        color: #fff;
                    }
                    .en {
                        color: #fff;
                    }
                }
            }
            .title-btn {
                background-color: #fff;
                color: $baseColor;
            }
        }
        .ts-info {
            margin-top: px2rem(34px);
            .ts-dsc {
                max-width: 610px;
                margin: 0 auto;
                font-size: 16px;
                line-height: 35px;
                color: #fff;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    font-size: 14px;
                    line-height: 1.3;
                }
            }
            .ts-img {
                margin-top: px2rem(70px);
                width: 100%;
                text-align: center;
                @media screen and (max-width: 1240px) {
                    margin-top: px2rem(40px);
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    .jb-content {
        padding: 146px 0 98px;
        box-sizing: border-box;
        @media screen and (max-width: 1240px) {
            padding: px2rem(60px) 0;
        }
        .jb-info {
            position: relative;
            margin-top: 76px;
            width: 100%;
            @media screen and (max-width: 1240px) {
                margin-top: px2rem(60px);
            }
            .box {
                width: 100%;
                position: relative;
                padding-bottom: (373 / 343) * 100%;
                overflow: hidden;
                img {
                    @include baseImg;
                    transition: all .3s;
                }
            }
            .box:hover {
                img {
                    transform: scale(1.1);
                }
            }
            .same {
                position: absolute;
                z-index: 4;
                top: 50%;
                transform: translateY(-50%);
                width: 54px;
                height: 54px;
                border-radius: 50%;
                background-color: $baseColor;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: .2;
                cursor: pointer;
                @media screen and (max-width: 1240px) {
                    width: 30px;
                    height: 30px;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    @media screen and (max-width: 1240px) {
                        max-width: none;
                        width: 20px;
                    }
                }
            }
            .same:hover {
                opacity: 1;
            }
            .jb-prev {
                left: 360px;
                @media screen and (max-width: 1240px) {
                    left: -30px;
                }
                @media screen and (max-width: 750px) {
                    left: 0px;
                }
            }
            .jb-next {
                right: 360px;
                @media screen and (max-width: 1240px) {
                    right: -30px;
                }
                @media screen and (max-width: 750px) {
                    right: 0px;
                }
            }
        }
    }
}