.about-contact {
    padding: px2rem(60px) 0 px2rem(100px);
    background-color: #f5f5f5;
    .contact-content {
        background-color: #fff;
        padding: 72px 54px 93px;
        box-sizing: border-box;
        border-radius: 15px;
        overflow: hidden;
        @media screen and (max-width: 1240px) {
            padding: px2rem(40px) px2rem(30px) px2rem(60px);
        }
        .contact-title {
            position: relative;
            font-size: 45px;
            line-height: 1;
            color: #4c4c4c;
            padding-bottom: px2rem(28px);
            box-sizing: border-box;

            @media screen and (max-width: 1240px) {
                font-size: px2rem(36px);
            }

        }
        .contact-title:before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            width: px2rem(60px);
            height: px2rem(7px);
            border-radius: px2rem(7px);
            background-color: $baseColor;
        }
        .select-info {
            display: flex;
            margin: px2rem(40px) px2rem(-16px) 0;
            @media screen and (max-width: 500px) {
                display: block;
            }
            .select-box {
                position: relative;
                width: 50%;
                padding: 0 px2rem(16px);
                box-sizing: border-box;
                @media screen and (max-width: 500px) {
                    width: 100%;
                }
                .box {
                    background-color: #f5f5f5;
                    border-radius: 10px;
                    overflow: hidden;
                    padding: 40px 28px 44px;
                    box-sizing: border-box;
                    cursor: pointer;
                    @media screen and (max-width: 1240px) {
                        padding: px2rem(30px);
                    }
                    .title {
                        font-size: 26px;
                        line-height: 1;
                        color: #666;
                        font-weight: bold;
                        padding-bottom: px2rem(21px);
                        margin-bottom: px2rem(30px);
                        box-sizing: border-box;
                        border-bottom: 1px dashed #ddd;
                        @media screen and (max-width: 1240px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width: 500px) {
                            font-size: 16px;
                        }

                    }
                    .bottom {
                        .same {
                            display: flex;
                            align-items: center;
                            span {
                                display: block;
                            }
                            .iconfont {
                                color: #666;
                                font-size: 22px;
                                @media screen and (max-width: 1240px) {
                                    font-size: 16px;
                                }
                            }
                            .word {
                                margin-left: px2rem(8px);
                                font-size: 14px;
                                line-height: 1;
                                color: #666;
                                @media screen and (max-width: 1240px) {
                                    font-size: 13px;
                                    line-height: 16px;
                                }
                            }
                        }
                        .same:nth-of-type(1) {
                            margin-bottom: px2rem(19px);
                        }
                    }
                }
            }
            .select-box:before {
                position: absolute;
                z-index: 9;
                content: none;
                bottom: -15px;
                right: 50%;
                -webkit-transform: translateX(50%);
                -moz-transform: translateX(50%);
                -ms-transform: translateX(50%);
                transform: translateX(50%);
                border-top: 15px solid $baseColor;
                border-left: 15px solid transparent;
                border-right: 15px solid transparent;
            }
            .on {
                .box {
                    background-color: $baseColor;
                    .title {
                        color: #fff;
                        border-bottom: 1px dashed #7dc4c1;
                    }
                    .bottom {
                        .same {
                            .iconfont,
                            .word {
                                color: #fff;
                            }
                        }
                    }
                }
            }
            .on:before {
                content: '';
                @media screen and (max-width: 500px) {
                    content: none;
                }
            }
            .select-box:nth-of-type(1) {
                @media screen and (max-width: 500px) {
                    margin-bottom: 8px;
                }
            }
        }
        .map-container {
            margin-top: px2rem(32px);
            .map-box {
                display: none;
                width: 100%;
                height: px2rem(538px);
                box-sizing: border-box;
                .map {
                    width: 100%;
                    height: 100%;
                }
            }
            .on {
                display: block;
            }
        }
    }
}