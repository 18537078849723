.about-profile {
    padding: px2rem(70px) 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .profile-content {
        margin-top: px2rem(40px);
        background-color: #fff;
        border-radius: 15px;
        padding: 50px 54px;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 35px;
        color: #888;
        @include articleReset(16px, 35px);
        @media screen and (max-width: 1240px) {
            padding: px2rem(30px);
            font-size: 14px;
            line-height: 24px;
            @include articleReset(14px, 24px);
        }
        .video {
            margin-top: px2rem(30px);
            position: relative;
            width: 100%;
            border-radius: 15px;
            padding-bottom: (395 / 1092) * 100%;
            overflow: hidden;
            cursor: pointer;
            video {
                @include baseImg;
                outline: none;
            }
            img {
                @include baseImg;
            }
        }
    }
}
.about-heart {
    padding: px2rem(60px) 0 px2rem(80px);
    box-sizing: border-box;
    .heart-content {
        margin-top: px2rem(40px);
        .info-dsc {
            padding: 0 60px;
            box-sizing: border-box;
            font-size: 16px;
            line-height: 35px;
            color: #888;
            @include articleReset(16px, 35px);
            @media screen and (max-width: 1240px) {
                padding: 0 px2rem(30px);
                font-size: 14px;
                line-height: 24px;
                @include articleReset(14px, 24px);
            }
        }
        .content-container {
            position: relative;
            .img {
                text-align: center;
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            .position {
                width: 100%;
                @media screen and (max-width: 1240px) {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    margin-top: px2rem(65px);
                }
                .same {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    background-color: $baseColor;
                    width: 340px;
                    padding: 28px 0 36px;
                    border-radius: 15px;
                    @media screen and (max-width: 1240px) {
                        position: relative;
                        width: calc(50% - 12px);
                        padding: px2rem(86px) 0 px2rem(36px);
                    }
                    .circle {
                        position: absolute;
                        top: 0;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        display: none;
                        width: px2rem(110px);
                        height: px2rem(110px);
                        line-height: px2rem(35px);
                        font-size: px2rem(32px);
                        font-family: "思源宋体 CN";
                        border-radius: 50%;
                        text-align: center;
                        color: #fff;
                        background-color: $onColor;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            display: flex;
                        }
                        @media screen and (max-width: 400px) {
                            padding: 5px;
                        }
                    }
                    .title {
                        font-size: 22px;
                        line-height: 30px;
                        font-weight: bold;
                        color: #fff;
                        transition: all .3s;
                        @media screen and (max-width: 1240px) {
                            font-size: px2rem(32px);
                            line-height: px2rem(30px);
                        }
                    }
                    .dsc {
                        margin-top: px2rem(15px);
                        text-align: center;
                        font-size: 18px;
                        line-height: 20px;
                        color: rgba(255, 255, 255, .8);
                        @media screen and (max-width: 1240px) {
                            height: 100%;
                            font-size: px2rem(28px);
                            line-height: px2rem(30px);
                        }
                    }
                }
                .same:hover {
                    .title {
                        color: $onColor;
                    }
                }
                .left {
                    top: 255px;
                    left: 25px;
                    @media screen and (max-width: 1240px) {
                        top: 0;
                        left: 0;
                    }
                }
                .left:before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    right: -15px;
                    border-top: 15px solid transparent;
                    border-left: 15px solid $baseColor;
                    border-bottom: 15px solid transparent;
                    @media screen and (max-width: 1240px) {
                        content: none;
                    }
                }
                .right {
                    top: 240px;
                    right: 25px;
                    @media screen and (max-width: 1240px) {
                        top: 0;
                        left: 0;
                    }
                }
                .right:before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    left: -15px;
                    border-top: 15px solid transparent;
                    border-right: 15px solid $baseColor;
                    border-bottom: 15px solid transparent;
                    @media screen and (max-width: 1240px) {
                        content: none;
                    }
                }
                .bottom {
                    position: absolute;
                    left: 50%;
                    bottom: 16px;
                    transform: translateX(-50%);
                    width: 952px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    background-color: $baseColor;
                    padding: px2rem(30px) 0;
                    box-sizing: border-box;
                    border-radius: 15px;
                    @media screen and (max-width: 1240px) {
                        position: relative;
                        transform: none;
                        width: 100%;
                        margin-top: px2rem(98px);
                        left: 0;
                        padding: px2rem(86px) 0 px2rem(36px);
                    }
                    .circle {
                        position: absolute;
                        top: 0;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        display: none;
                        width: px2rem(110px);
                        height: px2rem(110px);
                        line-height: px2rem(35px);
                        font-size: px2rem(32px);
                        font-family: "思源宋体 CN";
                        border-radius: 50%;
                        text-align: center;
                        color: #fff;
                        background-color: $onColor;
                        align-items: center;
                        justify-content: center;
                        padding: 10px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            display: flex;
                        }
                        @media screen and (max-width: 400px) {
                            padding: 5px;
                        }
                    }
                    .bottom-box {
                        width: 20%;
                        text-align: center;
                        border-right: 1px solid #61c2be;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            width: 33.3%;
                            margin-bottom: px2rem(30px);
                        }
                        .title {
                            font-size: 22px;
                            line-height: 1;
                            font-weight: bold;
                            color: #fff;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: px2rem(32px);
                                line-height: px2rem(30px);
                            }
                        }
                        .dsc {
                            margin-top: px2rem(19px);
                            text-align: center;
                            font-size: 18px;
                            line-height: 20px;
                            color: rgba(255, 255, 255, .8);
                            @media screen and (max-width: 1240px) {
                                height: 100%;
                                font-size: px2rem(28px);
                                line-height: px2rem(30px);
                            }
                        }
                    }
                    .bottom-box:nth-last-of-type(1) {
                        border-right: none;
                    }
                    .bottom-box:hover {
                        .title {
                            color: $onColor;
                        }
                    }
                }
                .bottom:before {
                    position: absolute;
                    z-index: 9;
                    content: '';
                    left: 50%;
                    transform: translateX(-50%);
                    top: -15px;
                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-bottom: 15px solid $baseColor;
                    @media screen and (max-width: 1240px) {
                        content: none;
                    }
                }
            }
        }
    }
}
.about-history {
    padding: px2rem(70px) 0;
    box-sizing: border-box;
    background-color: #f5f5f5;
    .history-content {
        margin-top: px2rem(40px);
        display: flex;
        @media screen and (max-width: 1240px) {
            display: block;
        }
        .content-left {
            width: (360 / 1200) * 100%;
            @media screen and (max-width: 1240px) {
                display: none;
            }
            .img {
                position: relative;
                width: 100%;
                padding-bottom: (770 / 360) * 100%;
                border-top-left-radius: 15px;
                border-bottom-left-radius: 15px;
                overflow: hidden;
                img {
                    @include baseImg;
                }
            }
        }
        .content-right {
            flex: 1;
            background-color: #fff;
            padding: 40px 30px 40px 70px;
            box-sizing: border-box;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 15px;
            @media screen and (max-width: 1240px) {
                padding: px2rem(30px);
            }
            .swiper-container {
                max-height: 690px;
                padding-right: 10px;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    max-height: none;
                    padding-right: 0;
                }
            }
            .swiper-slide {
                position: relative;
                box-sizing: border-box;
                .box {
                    margin-bottom: px2rem(20px);
                    .dsc-title {
                        width: 100%;
                        display: flex;
                        span {
                            display: inline-block;
                        }
                        .circle {
                            position: relative;
                            z-index: 2;
                            width: 50px;
                            height: 50px;
                            line-height: 50px;
                            text-align: center;
                            border-radius: 50%;
                            color: #fff;
                            font-size: 18px;
                            font-family: Arial;
                            font-weight: bold;
                            background-color: $baseColor;
                            @media screen and (max-width: 1240px) {
                                width: 40px;
                                height: 40px;
                                line-height: 40px;
                                font-size: 14px;
                            }
                        }
                        .word {
                            flex: 1;
                            margin-left: 10px;
                            font-size: 20px;
                            line-height: 50px;
                            overflow: hidden;
                            height: 50px;
                            color: #333;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 40px;
                                height: 40px;
                            }
                        }
                    }
                    .deeds {
                        font-size: 16px;
                        line-height: 55px;
                        color: #666;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            font-size: 14px;
                            line-height: 24px;
                        }
                        p {
                            position: relative;
                            padding-left: 65px;
                            @media screen and (max-width: 1240px) {
                                margin-bottom: 10px;
                                padding-left: 55px;
                            }
                        }
                        p:before {
                            position: absolute;
                            content: '';
                            left: 15px;
                            top: 50%;
                            transform: translateY(-50%);
                            border-radius: 50%;
                            z-index: 2;
                            width: 16px;
                            height: 16px;
                            border: 2px solid #ddd;
                            background-color: #fff;
                            @media screen and (max-width: 1240px) {
                                width: 10px;
                                height: 10px;
                                left: 12px;
                            }
                        }
                    }
                }
            }
            .swiper-slide:before {
                position: absolute;
                left: 24px;
                top: 1px;
                content: '';
                width: 2px;
                height: calc(100% - 15px);
                background-color: #ddd;
                @media screen and (max-width: 1240px) {
                    left: 18px;
                }
            }
        }
        .scrollbar-history {
            width: 6px;
            @media screen and (max-width: 750px) {
                display: none;
            }
            .swiper-scrollbar-drag {
                //height: 74px !important;
                background: rgba(0, 0, 0, .2) !important;
            }
        }
    }
}
.about-honor {
    padding: px2rem(50px) 0 px2rem(100px);
    box-sizing: border-box;
    background: url(../images/about5.png) top left;
    .honor-content {
        position: relative;
        margin-top: px2rem(40px);
        padding-bottom: px2rem(50px);
        @media screen and (max-width: 500px) {
            padding-bottom: 35px;
        }
        .content-box {
            background-color: #fff;
            padding: 9px;
            border: 1px solid #ddd;
            box-sizing: border-box;
            border-radius: 15px;
            overflow: hidden;
            cursor: pointer;
            .box-bk {
                width: 100%;

                .img {
                    position: relative;
                    width: 100%;
                    padding-bottom: (265 / 360) *100%;
                    overflow: hidden;
                    background-color: #f5f5f5;
                    img {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        max-width: 100%;
                        max-height: 100%;
                        transition: all .3s;
                        @media screen and (max-width: 750px) {
                            max-height: 80%;
                            max-width: 80%;
                        }
                    }
                }
                .name {
                    margin: 22px 0;
                    font-size: 16px;
                    line-height: 24px;
                    color: #333;
                    text-align: center;
                }
            }

            .box-bk:hover {
                .img {
                    img {
                        transform: translate(-50%, -50%) scale(1.1);
                    }
                }
                .name {
                    color: $baseColor;
                }
            }
        }
        .content-box:nth-of-type(1) {
            margin-bottom: px2rem(30px);
        }
        .cooperation-pagination {
            left: 50%;
            transform: translateX(-50%);
            bottom: 0px;
            .swiper-pagination-bullet {
                width: 10px;
                height: 10px;
                margin: 0 px2rem(5px);
                background-color: $baseColor;
                opacity: 1;
            }
            .swiper-pagination-bullet-active {
                background-color: $onColor;
            }
        }
    }
}
.tree-title {
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    max-width: 500px;
    width: fit-content;
    margin: 0 auto;
    @media screen and (max-width: 500px) {
        min-width: auto;
    }

    .title-left {
        margin-right: 15px;
        @media screen and (max-width: 1600px) {
            margin-right: 10px;
        }
        .img {
            img {
                max-width: 100%;
                height: auto;
                @media screen and (max-width: 500px) {
                    max-width: none;
                    width: 60px;
                }
            }
        }
    }
    .title-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        .left-box {
            height: 100%;
        }
        .cn {
            font-size: 45px;
            line-height: 42px;
            font-family: 微软雅黑;
            color: #4c4c4c;
            padding-bottom: px2rem(9px);
            box-sizing: border-box;
            white-space: nowrap;
            @media screen and (max-width: 1600px) {
                font-size: 32px;
                line-height: 32px;
            }
            @media screen and (max-width: 500px) {
                font-size: 22px;
                line-height: 22px;
            }
        }
        .en {
            padding-top: px2rem(9px);
            font-size: 14px;
            line-height: 14px;
            color: #999;
            font-family: Arial;
            text-transform: uppercase;
            @media screen and (max-width: 500px) {
                font-size: 12px;
                line-height: 12px;
            }
        }
    }
    .title-btn {
        position: relative;
        top: 13px;
        z-index: 2;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: $onColor;
        color: #fff;
        font-size: 16px;
        line-height: 27px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        @media screen and (max-width: 1600px) {
            top: 10px;
        }
        @media screen and (max-width: 500px) {
            top: 5px;
        }
        span {
            position: relative;
            top: 2px;
            left: -1px;
            display: inline-block;
            text-align: center;
            animation: trackBallSlide 6s linear infinite;
            @media screen and (max-width: 1600px) {
                left: 0;
            }
            @media screen and (max-width: 500px) {
                font-size: 12px;
            }
        }
    }
}
@keyframes trackBallSlide {
    0% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
    6% {
        opacity: 1;
        -webkit-transform: scale(.9) translateY(5px);
        transform: scale(.9) translateY(5px);
    }
    14% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(5px);
        transform: scale(.4) translateY(5px);
    }
    15%,
    19% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(-5px);
        transform: scale(.4) translateY(-5px);
    }
    28%,
    29.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
    30% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
    36% {
        opacity: 1;
        -webkit-transform: scale(.9) translateY(5px);
        transform: scale(.9) translateY(5px);
    }
    44% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(5px);
        transform: scale(.4) translateY(5px);
    }
    45%,
    49% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(-5px);
        transform: scale(.4) translateY(-5px);
    }
    58%,
    59.99% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
    60% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
    66% {
        opacity: 1;
        -webkit-transform: scale(.9) translateY(5px);
        transform: scale(.9) translateY(5px);
    }
    74% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(5px);
        transform: scale(.4) translateY(5px);
    }
    75%,
    79% {
        opacity: 0;
        -webkit-transform: scale(.4) translateY(-5px);
        transform: scale(.4) translateY(-5px);
    }
    88%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1) translateY(-5px);
        transform: scale(1) translateY(-5px);
    }
}
.tree-title:before {
    position: absolute;
    right: 0;
    top: 66%;
    content: '';
    width: calc(100% - 79px);
    height: 1px;
    background-color: #e5e5e5;
    @media screen and (max-width: 1600px) {
        top: 60%;
    }
    @media screen and (max-width: 500px) {
        width: calc(100% - 54px);
        top: 57%;
    }
}