.aiLearning {
    .edustion-title {
        color: #75be62;
        p {
            position: relative;
            font-weight: bold;
            margin-bottom: 0;
        }
        p:before {
            position: absolute;
            content: '';
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/aiLearning1.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
        p:after {
            position: absolute;
            content: '';
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 19px;
            background: url(../images/aiLearning2.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                content: none;
            }
        }
    }
    .edustion-container {
        .bk-img-edustion {
            background: url(../images/aiLearning3.png) left bottom;
            background-position: 100% 100%;
            background-repeat: no-repeat;
            padding-bottom: px2rem(42px);
            @media screen and (max-width: 1240px) {
                background: none;
            }
        }

        .edustion-produceAdvantages {
            background-color: #75be62;
            background-image: url(../images/aiLearning6.png);
            background-repeat: no-repeat;
            background-position: 100% 100%;
            @media screen and (max-width: 1240px) {
                background-image: none;
            }
        }
        .edustion-appCase {

            .appCase-content {

                .swiper-slide {

                    .right {
                        background-color: #75be62;

                    }
                }
                .same:hover {
                    background-color: #75be62;
                    color: #fff;
                    border: none;
                }
            }
        }
    }
}