.baiban {
    height: 71px;
    width: 100%;
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5;
    @media screen and (max-width: 1000px) {
        height: 50px;
    }
}
.join-container {
    background-color: #f5f5f5;
    padding-top: 135px;
    @media screen and (max-width: 1000px) {
        padding-top: px2rem(100px);
    }
    .rcln-title {
        color: #4450cd;
        p {
            position: relative;
            font-weight: bold;
            margin-bottom: 0;
            font-size: 34px;
            line-height: 1;
            @media screen and (max-width: 1000px) {
                font-size: 26px;
            }
        }
        p:before {
            position: absolute;
            content: '';
            top: 50%;
            left: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 20px;
            background: url(../images/rcln1.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            background-size: contain;
            @media screen and (max-width: 1000px) {
                width: 25px;
                height: 16px;
                left: -40px;
            }
        }
        p:after {
            position: absolute;
            content: '';
            top: 50%;
            right: -52px;
            transform: translateY(-50%);
            width: 32px;
            height: 20px;
            background: url(../images/rcln2.png) center center;
            background-repeat: no-repeat;
            background-position: 100% 100%;
            background-size: contain;
            @media screen and (max-width: 1000px) {
                width: 25px;
                height: 16px;
                right: -40px;
            }
        }
    }
    .join-content {
        background-color: #fff;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        overflow: hidden;
        @media screen and (max-width: 1000px) {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .join-rcln {
            padding: 80px 0 100px 80px;
            box-sizing: border-box;
            @media screen and (max-width: 1000px) {
                padding: 60px 0 px2rem(60px) px2rem(30px);
            }
            .rcln-content {
                width: 100%;
                margin-top: px2rem(50px);
                display: flex;
                align-items: flex-end;
                @media screen and (max-width: 1000px) {
                    display: block;
                }
                .rcln-left {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    box-sizing: border-box;
                    padding-right: 80px;
                    @media screen and (max-width: 1000px) {
                        margin-bottom: px2rem(60px);
                        padding-right: px2rem(30px);
                    }
                    .rcln-title {
                        padding-left: 55px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1000px) {
                            padding-left: 0;
                            text-align: center;
                            margin: 0 auto;
                        }
                    }
                    .rcln-dsc {
                        margin-top: px2rem(50px);
                        font-size: 21px;
                        line-height: 47px;
                        color: #585858;
                        width: 100%;
                        @media screen and (max-width: 1000px) {
                            margin-top: px2rem(30px);
                            font-size: 16px;
                            line-height: 28px;
                        }
                    }
                    .rcln-zt {
                        width: 100%;
                        font-size: 67px;
                        line-height: 152px;
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 4px;
                        color: #585858;
                        opacity: .1;
                        font-family: 微软雅黑;
                        @media screen and (max-width: 1000px) {
                            font-size: 36px;
                            line-height: 60px;
                            letter-spacing: 2px;
                        }
                    }
                }
                .rcln-right {
                    width: (553 / 1120) * 100%;
                    @media screen and (max-width: 1000px) {
                        width: 100%;
                        text-align: right;
                    }
                    .img {
                        text-align: right;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .join-zpgw {
            width: 100%;
            padding-bottom: px2rem(80px);
            font-size: 16px;
            line-height: 24px;
            color: #585858;
            @media screen and (max-width: 1000px) {
                font-size: 14px;
                line-height: 20px;
            }
            img {
                max-width: 100%;
                height: auto;
            }
            .top-title {
                background-size: cover !important;
                background-repeat: no-repeat !important;
                height: 224px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                margin-bottom: px2rem(76px);
                box-sizing: border-box;
                @media screen and (max-width: 1000px) {
                    height: 110px;
                    margin-bottom: px2rem(60px);
                    padding: 0 px2rem(30px);
                }
                .title-box {
                    .cn {
                        font-size: 34px;
                        line-height: 36px;
                        color: #fff;
                        font-weight: bold;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 26px;
                            line-height: 28px;
                        }
                    }
                    .en {
                        margin-top: 4px;
                        font-size: 12px;
                        line-height: 1;
                        font-weight: bold;
                        color: #fff;
                        text-align: center;
                        text-transform: uppercase;
                    }
                    .dz {
                        font-size: 34px;
                        line-height: 1.2;
                        font-weight: bold;
                        color: #fff;
                        text-transform: uppercase;
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            font-size: 26px;
                            line-height: 1.1;
                        }
                        @media screen and (max-width: 500px) {
                            font-size: 20px;
                            line-height: 1.1;
                        }
                    }
                }
            }
            .syzx-content {
                width: 100%;
                padding: 0 50px 0 70px;
                box-sizing: border-box;
                overflow: hidden;
                margin-bottom: px2rem(40px);
                @media screen and (max-width: 1000px) {
                    padding: 0 px2rem(30px) 0;
                    margin-bottom: px2rem(80px);
                }
                .zpgw-title {
                    display: flex;
                    justify-content: center;
                    margin-bottom: px2rem(86px);
                    @media screen and (max-width: 1000px) {
                        margin-bottom: px2rem(60px);
                    }
                    p {
                        position: relative;
                        font-size: 24px;
                        line-height: 1;
                        color: #585858;
                        @media screen and (max-width: 1000px) {
                            font-size: 20px;
                        }
                    }
                    p:before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        left: -72px;
                        transform: translateY(-50%);
                        width: 62px;
                        height: 3px;
                        background-color: #585858;
                        @media screen and (max-width: 1000px) {
                            width: 40px;
                            height: 2px;
                            left: -50px;
                        }
                    }
                    p:after {
                        position: absolute;
                        content: '';
                        top: 50%;
                        right: -72px;
                        transform: translateY(-50%);
                        width: 62px;
                        height: 3px;
                        background-color: #585858;
                        @media screen and (max-width: 1000px) {
                            width: 40px;
                            height: 2px;
                            right: -50px;
                        }
                    }
                }
                .content {
                    display: flex;
                    flex-wrap: wrap;
                    margin: -40px;
                    @media screen and (max-width: 1000px) {
                        margin: -20px;
                    }
                    .content-box {
                        width: 50%;
                        padding: 40px;
                        box-sizing: border-box;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        @media screen and (max-width: 1000px) {
                            padding: 20px;
                        }
                        @media screen and (max-width: 750px) {
                            width: 100%;
                        }
                        .img {
                            width: 167px;
                            @media screen and (max-width: 1000px) {
                                width: 80px;
                            }
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                        .name {
                            text-align: center;
                            font-size: 30px;
                            line-height: 36px;
                            font-weight: bold;
                            color: #4450cd;
                            margin-top: px2rem(22px);
                            @media screen and (max-width: 1000px) {
                                margin-top: px2rem(20px);
                                font-size: 24px;
                                line-height: 30px;
                            }
                        }
                        .info {
                            width: 100%;
                            height: 100%;
                            padding-top: px2rem(26px);
                            @media screen and (max-width: 1000px) {
                                padding-top: px2rem(20px);
                            }
                            .dsc {
                                font-size: 16px;
                                line-height: 24px;
                                color: #585858;
                                margin-bottom: 30px;
                                @media screen and (max-width: 1000px) {
                                    margin-bottom: px2rem(20px);
                                    font-size: 14px;
                                    line-height: 22px;
                                }
                                .bit {
                                    position: relative;
                                    padding-left: 15px;
                                    box-sizing: border-box;
                                    @media screen and (max-width: 1000px) {
                                        margin-bottom: px2rem(20px);
                                        padding-left: 10px;
                                    }
                                }
                                .bit:before {
                                    position: absolute;
                                    top: 10px;
                                    left: 5px;
                                    content: '';
                                    width: 5px;
                                    height: 5px;
                                    background-color: #fcbe00;
                                    border-radius: 50%;
                                    @media screen and (max-width: 1000px) {
                                        margin-bottom: px2rem(20px);
                                        top: 8px;
                                        left: 4px;
                                        width: 4px;
                                        height: 4px;
                                    }
                                }
                            }
                            .dsc:nth-last-of-type(1) {
                                margin-bottom: 0;
                            }
                        }
                        .img-phone {
                            img {
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
            }
            .gwfl-content {
                display: flex;
                padding-left: 60px;
                box-sizing: border-box;
                padding-top: 20px;
                @media screen and (max-width: 1000px) {
                    padding-left: px2rem(30px);
                    display: block;
                }
                .right {
                    width: (488 / 1140) * 100%;
                    @media screen and (max-width: 1000px) {
                        width: 100%;
                    }
                    .img {
                        width: 100%;
                        @media screen and (max-width: 1000px) {
                            text-align: right;
                        }
                    }
                }
                .left {
                    flex: 1;
                    padding-right: 10px;
                    @media screen and (max-width: 1000px) {
                        padding-right: px2rem(30px);
                        margin-bottom: px2rem(40px);
                    }
                    .dsc {
                        font-size: 16px;
                        line-height: 36px;
                        font-weight: bold;
                        color: #585858;
                        @media screen and (max-width: 1000px) {
                            font-size: 14px;
                            line-height: 28px;
                        }
                        p {
                            font-weight: bold;
                        }
                    }
                }
            }
            .sbdz-content {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 50px;
                box-sizing: border-box;
                @media screen and (max-width: 1000px) {
                    padding: 0 px2rem(30px);
                    display: block;
                }
                .right {
                    margin-left: 100px;
                    @media screen and (max-width: 1000px) {
                        margin-left: 0;
                        margin-top: px2rem(40px);
                    }
                    .content-box {
                        margin-bottom: 50px;
                        @media screen and (max-width: 1000px) {
                            margin-bottom: px2rem(40px);
                        }
                        .bold-title {
                            font-size: 24px;
                            line-height: 2;
                            font-weight: bold;
                            color: #585858;
                            @media screen and (max-width: 1000px) {
                                font-size: 18px;
                                line-height: 1.5;
                            }
                        }
                        .dsc {
                            font-size: 18px;
                            line-height: 2;
                            color: #585858;
                            @media screen and (max-width: 1000px) {
                                font-size: 14px;
                                line-height: 1.5;
                            }
                        }
                    }
                    .content-box:nth-last-of-type(1) {
                        margin-bottom: 0;
                    }
                }
                .left {
                    display: flex;
                    justify-content: center;
                    .img {
                        text-align: center;
                        @media screen and (max-width: 1000px) {
                            width: 100px;
                        }
                    }
                }
            }
        }
        .pb-no {
            padding-bottom: 0;
            .top-title {
                margin-bottom: 0;
            }
        }
    }
}