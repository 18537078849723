.solution-container {
    .solution-classroom {
        padding: px2rem(70px) 0 px2rem(60px);
        background-color: #f5f5f5;
        .classroom-content {
            margin-top: px2rem(40px);
            background-color: #fff !important;
            padding: 60px 55px 50px;
            box-sizing: border-box;
            background-repeat: no-repeat !important;
            border-radius: 15px;

            @media screen and (max-width: 1240px) {
                padding: px2rem(30px);
            }
            .video {
                position: relative;
                width: 100%;
                padding-bottom: (395 / 1090) * 100%;
                border-radius: 15px;
                overflow: hidden;
                cursor: pointer;
                video {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    outline: none;
                    cursor: pointer;
                }
                img {
                    @include baseImg;
                }
            }
            .content-box {
                margin-top: px2rem(36px);
                display: flex;
                @media screen and (max-width: 750px) {
                    display: block;
                }
                .left {
                    width: (720 / 1090) * 100%;
                    padding-right: 60px;
                    box-sizing: border-box;
                    @media screen and (max-width: 750px) {
                        width: 100%;
                        padding-right: 0px;
                    }
                    .title {
                        font-size: 30px;
                        line-height: 40px;
                        font-weight: bold;
                        color: #1ca7a2;
                        @media screen and (max-width: 1240px) {
                            font-size: 22px;
                            line-height: 30px;
                        }
                    }
                    .dsc {
                        margin-top: px2rem(20px);
                        font-size: 16px;
                        line-height: 35px;
                        color: #888;
                        @media screen and (max-width: 1240px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
                .right {
                    flex: 1;
                    @media screen and (max-width: 750px) {
                        margin-top: px2rem(30px);
                    }
                    .img {
                        img {
                            max-width: 100%;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
    .solution-scenarios {
        background-color: #fff;
        padding: px2rem(60px) 0 px2rem(70px);
        box-sizing: border-box;
        .scenarios-content {
            position: relative;
            margin-top: px2rem(40px);
            padding: 0 80px;
            @media screen and (max-width: 1240px) {
                padding: 0 px2rem(60px);
            }
            .scenarios-container {
                width: 100%;
                .swiper-slide {
                    position: relative;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (435 / 580) * 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .dsc {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        border-radius: 15px;
                        overflow: hidden;
                        width: 100%;
                        font-size: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: .95;
                        background-color: $baseColor;
                        padding: px2rem(30px) px2rem(20px);
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            padding: px2rem(20px);
                        }

                        @media screen and (max-width: 500px) {
                            font-size: 16px;
                        }
                        p {
                            opacity: 1;
                            color: #fff;
                        }
                    }
                }
                .swiper-slide:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .dsc {
                        background-color: $onColor;
                    }
                }
            }
            .same {
                position: absolute;
                z-index: 2;
                top: 50%;
                transform: translateY(-50%);
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: #333;
                outline: none;
                transition: all .3s;
                cursor: pointer;
                border: 1px solid #888;
                box-sizing: border-box;
                @media screen and (max-width: 1240px) {
                    width: 40px;
                    height: 40px;
                }
                @media screen and (max-width: 500px) {
                    width: 25px;
                    height: 25px;
                }
            }
            .same:hover {
                background-color: $baseColor;
                color: #fff;
                border: none;
            }
            .scenarios-next {
                right: 0;
            }
            .scenarios-prev {
                left: 0;
                transform: translateY(-50%) rotate(180deg);
            }
        }
    }
    .solution-advantages {
        padding: px2rem(60px) 0 px2rem(100px);
        background-color: #f5f5f5;
        .advantages-title {
            position: relative;
            font-size: 26px;
            line-height: 1;
            font-weight: bold;
            color: $baseColor;
            text-align: center;
            display: flex;
            justify-content: center;
            @media screen and (max-width: 1240px) {
                font-size: 20px;
                padding: 0 px2rem(30px);
                line-height: 1.3;
            }
            p {
                position: relative;
                font-weight: bold;
            }
            p:before {
                position: absolute;
                content: '';
                top: 50%;
                left: -86px;
                transform: translateY(-50%);
                width: 66px;
                height: 12px;
                background: url(../images/solution22.png) center center;
                background-repeat: no-repeat;
                background-position: 100% 100%;
                @media screen and (max-width: 1240px) {
                    content: none;
                }

            }
            p:after {
                position: absolute;
                content: '';
                top: 50%;
                right: -86px;
                transform: translateY(-50%);
                width: 66px;
                height: 12px;
                background: url(../images/solution21.png) center center;
                background-repeat: no-repeat;
                @media screen and (max-width: 1240px) {
                    content: none;
                }
            }
        }
        .dsc-same {
            font-size: 16px;
            line-height: 35px;
            color: #4c4c4c;
            @include articleReset(16px, 35px);
            @media screen and (max-width: 1240px) {
                font-size: 16px;
                line-height: 24px;
                @include articleReset(16px, 24px);
            }
            .img {
                text-align: center;
            }
        }
        .same-content {
            border-top: 1px dashed #ddd;
            padding-top: px2rem(30px);
            margin-top: px2rem(40px);
            box-sizing: border-box;
        }
        .process {
            margin-top: px2rem(40px);
            background-color: #fff;
            padding: px2rem(70px) px2rem(55px);
            box-sizing: border-box;
            border-top-right-radius: 15px;
            border-top-left-radius: 15px;
            overflow: hidden;
        }
        .teach {
            padding: px2rem(60px) px2rem(55px) px2rem(70px);
            box-sizing: border-box;
            background-color: #eaf7f7;
        }
        .resources {
            padding: px2rem(60px) px2rem(55px);
            box-sizing: border-box;
            background-color: #fff;
        }
        .wisdom {
            padding: px2rem(60px) px2rem(55px) px2rem(70px);
            box-sizing: border-box;
            background-color: #eaf7f7;
            .electronics-container {
                display: flex;
                flex-wrap: wrap;
                border-radius: 15px;
                overflow: hidden;
                margin-top: px2rem(20px);
                @media screen and (max-width: 1240px) {
                    display: block;
                }
                .info-box {
                    flex: 1;
                    @media screen and (max-width: 1240px) {
                        margin-bottom: px2rem(20px);
                    }
                    .first {
                        display: flex;
                        height: 105px;
                        overflow: hidden;
                        @media screen and (max-width: 1240px) {
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                        }
                        .box {
                            flex: 1;
                            font-size: 20px;
                            line-height: 30px;
                            font-family: "思源黑体 CN";
                            color: #fff;
                            background-color: $baseColor;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 18px 18px 28px;
                            box-sizing: border-box;
                            text-align: center;
                            @media screen and (max-width: 1000px) {
                                font-size: 16px;
                                line-height: 24px;
                                padding: px2rem(15px) px2rem(15px) px2rem(30px);
                            }

                        }
                        .box:nth-of-type(2) {
                            background-color: #178d89;
                        }
                    }
                    .second {
                        position: relative;
                        margin-top: -15px;
                        background-color: #fff;
                        display: flex;
                        height: 260px;
                        @media screen and (max-width: 1240px) {
                            border-top-right-radius: 15px;
                            border-top-left-radius: 15px;
                            height: auto;
                        }
                        .box {
                            flex: 1;
                            padding: px2rem(40px) 0 0;
                            box-sizing: border-box;
                            border-right: 1px solid #e5e5e5;
                            border-bottom: 1px solid #e5e5e5;
                            @media screen and (max-width: 1240px) {
                                padding: px2rem(30px) 0;
                            }
                            .img {
                                text-align: center;
                                height: 101px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                img {
                                    max-width: 100%;
                                    height: auto;
                                    @media screen and (max-width: 500px) {
                                        width: 80px;
                                    }
                                }
                            }
                            .name {
                                margin-top: px2rem(40px);
                                text-align: center;
                                font-size: 18px;
                                line-height: 30px;
                                color: #333;
                                padding: 0 px2rem(35px);
                                box-sizing: border-box;
                                @media screen and (max-width: 1240px) {
                                    font-size: 14px;
                                    line-height: 22px;
                                    padding: 0 px2rem(20px);
                                }
                            }
                        }
                    }
                    .third {
                        background-color: #fff;
                        height: 100%;
                        @media screen and (max-width: 1240px) {
                            border-bottom-right-radius: 15px;
                            border-bottom-left-radius: 15px;
                        }
                        .dsc {
                            padding: px2rem(34px) px2rem(25px) px2rem(11px) px2rem(35px);
                            font-size: 18px;
                            line-height: 30px;
                            font-family: "思源黑体 CN";
                            color: #333;
                            @include articleReset(18px, 30px);

                            @media screen and (max-width: 1240px) {
                                font-size: 15px;
                                line-height: 24px;
                                @include articleReset(15px, 24px);
                                padding: px2rem(30px) px2rem(30px) px2rem(40px);
                            }
                            p {
                                position: relative;
                            }
                            p:before {
                                position: absolute;
                                content: '';
                                top: 12px;
                                left: -14px;
                                width: 6px;
                                height: 6px;
                                border-radius: 50%;
                                background-color: $baseColor;
                                @media screen and (max-width: 1240px) {
                                    left: -10px;
                                    width: 5px;
                                    height: 5px;
                                    top: 8px;
                                }
                            }
                        }
                    }
                }
                .info-box:nth-of-type(1) {
                    .second {
                        border-top-left-radius: 15px;
                        overflow: hidden;
                        @media screen and (max-width: 1240px) {
                            border-top-right-radius: 15px;
                        }
                    }
                }
                .info-box:nth-of-type(3) {
                    @media screen and (max-width: 1240px) {
                        margin-bottom: 0;
                    }
                    .second {
                        border-top-right-radius: 15px;
                        overflow: hidden;
                    }
                }
                .info-box:nth-of-type(2) {
                    .second {
                        background-color: #f5f5f5;
                    }
                    .third {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
        .school {
            padding: px2rem(60px) px2rem(55px) px2rem(70px);
            box-sizing: border-box;
            background-color: #fff;
        }
        .service {
            padding: px2rem(60px) 0 px2rem(70px);
            box-sizing: border-box;
            background-color: #eaf7f7;
            .service-content {
                position: relative;
                margin-top: px2rem(40px);
                padding: 0 px2rem(55px);
                .box {
                    width: 100%;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (395 / 530) * 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .dsc-box {
                        position: relative;
                        z-index: 1;
                        margin-top: -45px;
                        background: url(../images/solution23.png) top left;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        padding: 40px 45px 0 50px;
                        height: 230px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            margin-top: -20px;
                            padding: px2rem(30px);
                            height: auto;
                        }
                        .title {
                            font-size: 32px;
                            line-height: 1;
                            color: #fff;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: 18px;
                            }
                            @media screen and (max-width: 500px) {
                                font-size: 16px;
                            }
                        }
                        .dsc {
                            font-size: 16px;
                            line-height: 35px;
                            height: 140px;
                            overflow: hidden;
                            color: #fff;
                            margin-top: px2rem(20px);
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                                height: 72px;
                            }
                        }
                    }
                }
                .box:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .dsc-box {
                        .title {
                            transform: translateX(10px);
                        }
                    }
                }
                .same {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #333;
                    outline: none;
                    transition: all .3s;
                    cursor: pointer;
                    border: 1px solid #d3d6d6;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        width: 40px;
                        height: 40px;
                    }
                    @media screen and (max-width: 500px) {
                        width: 25px;
                        height: 25px;
                    }
                }
                .same:hover {
                    background-color: $baseColor;
                    color: #fff;
                    border: none;
                }
                .service-next {
                    right: 20px;
                    @media screen and (max-width: 1240px) {
                        right: 0;
                    }
                }
                .service-prev {
                    left: 20px;
                    transform: translateY(-50%) rotate(180deg);
                    @media screen and (max-width: 1240px) {
                        left: 0;
                    }
                }
            }
        }
        .school-logo {
            padding: px2rem(60px) px2rem(55px) px2rem(70px);
            box-sizing: border-box;
            background-color: #fff;
            .school-logo-content {
                margin-top: px2rem(40px);
                padding-bottom: px2rem(50px);
                box-sizing: border-box;
                position: relative;
                .swiper-slide {
                    width: 100%;
                    .box {
                        width: 100%;
                        margin-bottom: 10px;
                        padding: 10px;
                        box-sizing: border-box;
                        border: 1px solid #e5e5e5;
                        border-radius: 15px;
                        overflow: hidden;
                        .img {
                            position: relative;
                            width: 100%;
                            padding-bottom: (80 / 245) * 100%;
                            img {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                max-width: 100%;
                                height: auto;
                            }
                        }
                    }
                    .box:nth-last-of-type(1) {
                        margin-bottom: 0;
                    }
                }
                .swiper-pagination-bullets {
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    .swiper-pagination-bullet {
                        margin: 0 px2rem(10px);
                        width: 10px;
                        height: 10px;
                        background-color: $baseColor;
                        opacity: 1;
                        @media screen and (max-width: 500px) {
                            width: 6px;
                            height: 6px;
                        }
                    }
                    .swiper-pagination-bullet-active {
                        background-color: $onColor;
                    }
                }
            }
        }
        .succeed-case {
            padding: px2rem(60px) px2rem(55px) px2rem(220px);
            box-sizing: border-box;
            background-color: #eaf7f7;
        }
        .achievement {
            padding: 1px px2rem(55px) px2rem(70px);
            background-color: #fff;
            box-sizing: border-box;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            .achievement-top {
                position: relative;
                margin-top: px2rem(-190px);
                display: flex;
                @media screen and (max-width: 1240px) {
                    display: block;
                }
                .left {
                    width: (530 / 1090) * 100%;
                    @media screen and (max-width: 1240px) {
                        width: 100%;
                    }
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (397 / 530) * 100%;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                }
                .right {
                    flex: 1;
                    position: relative;
                    margin-left: -30px;
                    background: url(../images/solution24.png) top left;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    padding: 70px 45px 30px 55px;
                    box-sizing: border-box;
                    border-radius: 15px;
                    overflow: hidden;
                    @media screen and (max-width: 1240px) {
                        margin-left: 0;
                        margin-top: -20px;
                        padding: px2rem(30px);
                    }
                    .right-content {
                        width: 100%;
                        .title {
                            font-size: 24px;
                            line-height: 35px;
                            color: #fff;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                        .dsc {
                            margin-top: px2rem(15px);
                            font-size: 16px;
                            line-height: 35px;
                            color: #fff;
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                            }
                        }
                    }
                }
            }
            .achievement-top:hover {
                .left {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
            .achievement-bottom {
                position: relative;
                margin-top: px2rem(38px);
                .box {
                    width: 100%;
                    .img {
                        position: relative;
                        width: 100%;
                        padding-bottom: (397 / 530) * 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        img {
                            @include baseImg;
                            transition: all .3s;
                        }
                    }
                    .dsc-box {
                        position: relative;
                        z-index: 1;
                        margin-top: -45px;
                        background: url(../images/solution23.png) top left;
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        border-radius: 15px;
                        overflow: hidden;
                        padding: 40px 45px 0 50px;
                        height: 230px;
                        box-sizing: border-box;
                        @media screen and (max-width: 1240px) {
                            margin-top: -20px;
                            padding: px2rem(30px);
                            height: auto;
                        }
                        .title {
                            font-size: 32px;
                            line-height: 1;
                            color: #fff;
                            transition: all .3s;
                            @media screen and (max-width: 1240px) {
                                font-size: 16px;
                                line-height: 24px;
                            }

                        }
                        .dsc {
                            font-size: 16px;
                            line-height: 35px;
                            height: 140px;
                            overflow: hidden;
                            color: #fff;
                            margin-top: 20px;
                            @media screen and (max-width: 1240px) {
                                font-size: 13px;
                                line-height: 18px;
                                height: 72px;
                                margin-top: px2rem(15px);
                            }
                        }
                    }
                }
                .box:hover {
                    .img {
                        img {
                            transform: scale(1.1);
                        }
                    }
                    .dsc-box {
                        .title {
                            transform: translateX(10px);
                        }
                    }
                }
                .same {
                    position: absolute;
                    z-index: 2;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    background-color: #fff;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 12px;
                    color: #333;
                    outline: none;
                    transition: all .3s;
                    cursor: pointer;
                    border: 1px solid #d3d6d6;
                    box-sizing: border-box;
                    @media screen and (max-width: 1240px) {
                        width: 40px;
                        height: 40px;
                    }
                    @media screen and (max-width: 500px) {
                        width: 30px;
                        height: 30px;
                    }
                }
                .same:hover {
                    background-color: $baseColor;
                    color: #fff;
                    border: none;
                }
                .achievement-next {
                    right: -30px;
                    @media screen and (max-width: 500px) {
                        right: 0px;
                    }
                }
                .achievement-prev {
                    left: -30px;
                    transform: translateY(-50%) rotate(180deg);
                    @media screen and (max-width: 500px) {
                        left: 0px;
                    }
                }
            }
        }
    }
}
.follow-fixed {
    display: none;
    position: fixed;
    z-index: 9;
    left: calc(50vw - 805px);
    top: 170px;
    width: 197px;
    background-color: #bfbfbf;
    padding: 60px 0 50px;
    box-sizing: border-box;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;
    transition: all .3s;
    animation-name: b2t;
    -webkit-animation-name: b2t;
    -ms-animation-name: b2t;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    -ms-animation-timing-function: linear;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    animation-duration: .5s;
    -webkit-animation-duration: .5s;
    -ms-animation-duration: .5s;
    @media screen and (max-width: 1600px) {
        left: 0;
        width: auto;
        padding: px2rem(20px) 0 ;
    }
    @media screen and (max-width: 1000px) {
        display: none !important;
    }
    .list {
        width: 100%;
        a {
            display: block;
            width: 100%;
            padding: 15px 0 15px 20px;
            box-sizing: border-box;
            font-size: 16px;
            color: #333;
            @media screen and (max-width: 1500px) {
                padding: px2rem(10px);
                font-size: 12px;
            }
        }
    }
    .on {
        a {
            background-color: #169adb;
            color: #fff;
        }
    }
}

@keyframes b2t {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
@-webkit-keyframes b2t {
    0% {
        -webkit-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateY(0);
        opacity: 1;
    }
}
@-ms-keyframes b2t {
    0% {
        -ms-transform: translateY(20px);
        opacity: 0;
    }
    100% {
        -ms-transform: translateY(0);
        opacity: 1;
    }
}